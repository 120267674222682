import React from 'react';

import InfoCard, { Header, Body, BodyContainer, BodyLink } from './styles';

const ListInfoCard = ({ headerText, bodyText, bodyLink }) => (
  <InfoCard>
    <Header>{headerText}</Header>
    <BodyContainer>
      <Body>{bodyText}</Body>
      <BodyLink>{bodyLink}</BodyLink>
    </BodyContainer>
  </InfoCard>
);

ListInfoCard.defaultProps = {
  headerText: '',
  bodyText: '',
  bodyLink: null,
};

export default ListInfoCard;
