import React from 'react';
import IframeResizer from 'react-iframe-resizer-super';

class FormAssemblyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      formContent: null,
      initialHref: window.location.href,
    };
  }

  componentDidMount() {
    this.loadForm();
  }

  async loadForm() {
    this.setState({ loading: true });
    try {
      const response = await fetch(
        `/api/formassembly/view/${this.props.formId}/`,
        {
          method: 'GET',
          headers: { Accept: 'text/html', 'Content-Type': 'text/html' },
          credentials: 'include',
        },
      );
      const content = await response.text();
      // The following approach does not respect validation error handling
      // - however we still get the response in FormAssembly
      const redirectScript = `
      window.addEventListener("unload", function (event) {
        if (window.top.location.href === "${this.state.initialHref}") {
          window.top.location.href = "${this.props.successUrl}";
        }
      });
      `;
      this.setState({
        loading: false,
        formContent: `<script>${redirectScript}</script>${content}`,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
      window.Intercom('showNewMessage', this.props.intercomMessage);
    }
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <p>The form is loading, please wait...</p>
        ) : (
          <IframeResizer
            id={`formassembly-${this.props.formId}`}
            title="Form"
            content={this.state.formContent}
            iframeResizerOptions={{ checkOrigin: false }}
          />
        )}
      </div>
    );
  }
}

export default FormAssemblyForm;
