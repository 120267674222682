/* eslint-disable */
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import RequestAQuoteForm from '../../../components/RequestAQuoteForm';
import { PageNotFoundInner } from '../../Static/PageNotFound';
import { resolveProviderServiceAreas } from '../../../util/transforms';
import { RouteComponentProps } from 'react-router';
import { Provider, RequestQuoteResult, Service, ServiceArea } from '../../../state/types';
import {trackQuoteSubmission} from "../tracking";
import { useAppSelector } from '../../../state/hooks';
import { useCreateRequestForQuoteFormMutation, useGetProviderDetailsQuery, useGetProviderDetailsUsingSlugQuery } from '../../../services/karista';
import { OrangeLink } from '../../../components/Link';
import { useGetAllAgeGroupsQuery, useGetAllFundingTypesQuery } from '../../../services/karista';
import { reduceQueries } from '../../../util/rtk-query';
import Theme from '../../../components/Theme';
import { themeLightGrey } from '../../../styles/constants';
import { postcodeExactDetailText } from '../../../util/transforms';
import FormProgressBar from '../../../components/FormProgressBar';
import karistaApi from "../../../services/karista";
import { HeroSection, FormSection, HeaderTextPurple, LeftAlignedContainer, BetaHeaderHeading, LeftAlignedHeader } from './styles';

export interface RequestQuoteProps extends RouteComponentProps<{id?: string, slug?: string}> {
  services: Service[]
  serviceAreas: ServiceArea[]
  requestQuoteResult: RequestQuoteResult
};

interface InnerRequestQuoteProps extends Omit<RequestQuoteProps, 'services'> {
  selectedProvider: Provider & {serviceAreas: ServiceArea[]},
  isProviderError: boolean,
  isLoading: boolean,
};

const useRequestQuoteProps = (props: RequestQuoteProps): InnerRequestQuoteProps => {
  const {
    match: {
      params: { id: providerId, slug: providerSlug },
    },
    services,
    serviceAreas,
  } = props;

  // const {data: rawProvider, isLoading, isError} = useGetProviderDetailsQuery(parseInt(providerId as string));
  const {data: rawProvider, isLoading, isError} = useGetProviderDetailsUsingSlugQuery(providerSlug as string);

  const selectedProvider = React.useMemo(
    () => resolveProviderServiceAreas(services, serviceAreas, rawProvider),
    [rawProvider, services],
  );
  return {
    ...props,
    selectedProvider: selectedProvider as (Provider & {serviceAreas: ServiceArea[]}),
    isProviderError: isError,
    isLoading: !services || isLoading,
  };
}

const RequestQuote: React.FC<RequestQuoteProps> = props => {
  const {
    selectedProvider,
    isProviderError,
    isLoading,
  } = useRequestQuoteProps(props);

  let steps = ['About You', 'Participant Details', 'Confirmation'];
  const [currentStep, setCurrentStep] = useState(0);
  const filters = useAppSelector(state => state.providers.providerSearchParams.filters);
  if (filters.funding_types_list && ["HCP", "HCP1", "HCP2", "HCP3", "HCP4"].includes(filters.funding_types_list)){
    steps = ['About You', 'Recipient Details', 'Confirmation'];
  }

  const { serviceAreas, id, ...providerRest } = selectedProvider;

  const [createRequestForQuote] = useCreateRequestForQuoteFormMutation();

  const useInitialize = () => reduceQueries({
    ageGroups: useGetAllAgeGroupsQuery(),
    fundingTypes: useGetAllFundingTypesQuery()
  });
  
  const { ageGroups, fundingTypes } = useInitialize();
  const ageGroupsData = ageGroups.data;
  const fundingTypesData = fundingTypes.data

  const handleSubmit = React.useCallback((formData, cb?) => {
    const submitData = Object.assign(
      {},
      { providers: [selectedProvider.id] },
      { ...formData, ...filters }
    );
    return createRequestForQuote(submitData)
      .unwrap()
      .then(async (res) => {
        const service = serviceAreas.find(area => area.id === parseInt(formData.service_area));
        await trackQuoteSubmission(service ? service.name : undefined);
        return res;
      })
      .then((res) => {
        const fundingType = fundingTypesData && fundingTypesData[formData.funding_types_list]
        const fundingTypeCheck = fundingType ? fundingType.code : undefined
        if (fundingTypeCheck && ["HCP", "HCP1", "HCP2", "HCP3", "HCP4"].includes(fundingTypeCheck)) {
          props.history.push(`/providers/quote-detail/additional-questions-hcp/${res.id}`);
        }
        else {
          props.history.push(`/providers/quote-detail/additional-questions-ndis/${res.id}`);
        }
      });
  }, [selectedProvider]);

  if (isProviderError) {
    return <PageNotFoundInner />;
  }

  if (!serviceAreas || isLoading || !ageGroups || !fundingTypes) {
    return <div>Loading ...</div>;
  }

  const selectedServiceArea = serviceAreas.find(
    serviceArea =>
      serviceArea.name == filters['service_areas_list']
  );

  const selectedAgeGroup = filters.age_groups_list && ageGroupsData && ageGroupsData[filters.age_groups_list];
  const selectedFundingType = filters.funding_types_list && fundingTypesData && fundingTypesData[filters.funding_types_list];
  const { data: postcodesDetail } = karistaApi.useGetPostcodeQuery(
    !!filters.postcodes_list ? filters.postcodes_list : skipToken,
  );

  return (
    <Theme theme={themeLightGrey}>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {providerRest.name &&
        !!providerRest.name &&  (
        <>
        <FormProgressBar steps={steps} currentStep={currentStep} />
        <HeroSection>
          <div>
            <LeftAlignedHeader>
              <BetaHeaderHeading>About You</BetaHeaderHeading>
            </LeftAlignedHeader>
            <FormSection>
              <HeaderTextPurple><b>Let us help you connect to {providerRest.name}</b></HeaderTextPurple>
              <p>
                Please answer these questions to help us connect you.
              </p>
              <RequestAQuoteForm
                selectedServiceArea={selectedServiceArea && selectedServiceArea.id}
                serviceAreasList={serviceAreas}
                serviceAreaCheck={filters.service_areas_list}
                providerId={id}
                onSubmit={handleSubmit}
                postcode={filters.postcodes_list}
                fundingType={filters.funding_types_list}
                ageGroup={filters.age_groups_list}
                fundingTypeList={fundingTypesData}
                ageGroupList={ageGroupsData}
                providerSlug={providerRest.url_slug}
                suburb={filters.suburb_list}
              />
              </FormSection>
            </div>
            <LeftAlignedContainer>
              {filters.service_areas_list && filters.postcodes_list && filters.age_groups_list && filters.funding_types_list ? 
                (
                  <div>
                    <p> You have selected <b>{providerRest.name}</b> 
                    <p> for <b>{filters.service_areas_list}</b> </p>
                    {(filters.suburb_list && filters.postcodes_list) ? (
                      <p>in <b>{postcodeExactDetailText(filters.suburb_list, filters.postcodes_list, postcodesDetail)}</b></p>
                    ) : filters.postcodes_list ? (
                      <p>in <b>{filters.postcodes_list}</b></p>
                    ) : null}
                    <p> for {" "} <b>{selectedAgeGroup && selectedAgeGroup.description}</b> </p>
                    <p> under <b>{selectedFundingType && selectedFundingType.name}</b>.</p>
                        {" "}
                        <OrangeLink to="/">
                          Modify search
                        </OrangeLink> 
                        <br></br>
                      </p>
                  </div>
               ): ( 
                  <div>
                    <p> You have selected <b>{providerRest.name}</b> </p>
                    {" "}
                        <OrangeLink to="/providers">
                          Change provider
                        </OrangeLink> 
                        <br></br>
                  </div>
                )
            }</LeftAlignedContainer>
        </HeroSection>
        </>
      )}
    </Theme>
  );
};

export default RequestQuote;
