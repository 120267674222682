import React, { useState } from 'react';

import Empty from '../Empty';
import { ProvidersActions } from '../../actions';
import Section from '../../../../components/Section';
import { AlphaHeading, BetaHeading, GammaHeading } from '../../../../components/Typography';
import SocialLink from '../../../../components/SocialLink';
import KaristaClients from './karista-clients.svg';
import styled from 'styled-components';
import { colors } from '../../../../styles/constants';
import Breakpoint from '../../../../components/Breakpoint';

const cardStackBreakpoint = '720px';

const Image = styled.img`
  width: 350px;
  height: 460px;

  @media (max-width: ${cardStackBreakpoint}) {
    margin-top: -60px;
    margin-bottom: -60px;
    align-items: center;
  }
`;

const ConatinerSection = styled(Section)`
  display: flex;
  flex-direction: row;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    align-items: center;
  }
`;

const ConatinerSubSection = styled.div`
  margin-top: 60px;
`;

const MainHeading = styled(AlphaHeading)`
  color: ${colors.purple};
`;

const SubHeading = styled(BetaHeading)`
  color: ${colors.purple};
`;

const SubSubHeading = styled(GammaHeading)`
  color: ${colors.black};
`;

export const Success = () => (
  <Breakpoint>
    {breakpoint => (
        breakpoint === 'desktop' ? (  
          <ConatinerSection>
            <Image src={KaristaClients} alt={"We can find providers"} />
            <ConatinerSubSection>
              <MainHeading>
                Thank you!
              </MainHeading>
              <SubHeading>
                Your information has been received.
              </SubHeading>
              <SubSubHeading>
                A member of our Client Services team will be in touch soon.
              </SubSubHeading>
              <SocialLink />
            </ConatinerSubSection>
          </ConatinerSection>
        ) : (
          <ConatinerSection>
            <ConatinerSubSection>
                <MainHeading>
                  Thank you!
                </MainHeading>
                <SubHeading>
                  Your information has been received.
                </SubHeading>
                <SubSubHeading>
                  A member of our Client Services team will be in touch soon.
                </SubSubHeading>
                <Image src={KaristaClients} alt={"We can find providers"} />  
              </ConatinerSubSection>
              <SocialLink />
          </ConatinerSection>
        )
    )}
  </Breakpoint>
);


const NoResults = (props) => {
  const { filters, ageGroups, fundingTypes, serviceAreas, createNoProviderFoundContactRequest } = props;

  const [formSubmitted, setFormSubmitted] = useState(false);

  const setSubmitted = (formSubmitted) => {
    setFormSubmitted(formSubmitted);
  };

  return (
    formSubmitted ? (
      <Success />
    ) : (
      <Empty 
        filters={filters}
        ageGroups={ageGroups}
        fundingTypes={fundingTypes}
        serviceAreas={serviceAreas}
        setSubmitted={formSubmitted => setSubmitted(formSubmitted)}
        createNoProviderFoundContactRequest={createNoProviderFoundContactRequest}
      />
    )
)};

export default NoResults;
