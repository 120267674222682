import React from 'react';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import ValidationError from '../ValidationError';

const MultiSelect = ({ input, fieldOptions, setValue, ...props }) => {
  const handleChange = (selectedOptions) => {
    setValue(
      input.name,
      selectedOptions.map((option) => option.value),
    );
  };

  return (
    <div>
      {!isEmpty(fieldOptions) && (
        <div>
          <Select
            {...props}
            {...input}
            options={fieldOptions}
            isMulti
            closeMenuOnSelect={false}
            classNamePrefix="react-select"
            onChange={handleChange}
          />
          <ValidationError {...props} />
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
