import styled from 'styled-components';
import mediaQueries from '../../styles/mediaQueries';
import { colors } from '../../styles/constants';
import { Centered } from '../Layout';
import Link from '../Link';
import Input from '../Input';

export const CenteredWithGutters = styled(Centered)`
  max-width: 720px;
  margin: 16px auto;
  text-align: left;
`;

export const StyledLabel = styled.text`
  font-size: 16px;
  color: ${colors.black};
`;

export const SubLabel = styled.text`
  line-height: 24px;
  margin-bottom: 6px;
  font-size: 16px;
  color: ${colors.black};
`;

export const BtnContainer = styled.div`
  margin: 0 auto;
`;

export const BottomLink = styled(Link)`
  color: ${colors.darkPurple};
  text-decoration: underline;
`;

export const HalfInput = styled(Input)`
  width: 50%;
  ${mediaQueries.mobile`
    width: 100%;
  `};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${mediaQueries.mobile`
    flex-direction: column;
  `};
`;

export const PrivacyLabel = styled.text`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  margin-bottom: 16px;
  color: ${colors.black};

  ${mediaQueries.mobile`
    flex-direction: column;
    width: 312px;
  `};
`;

export const SubmitLabel = styled.text`
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  margin-bottom: 16px;
  color: ${colors.black};

  ${mediaQueries.mobile`
    flex-direction: column;
    font-size: 12px;
    width: 320px;
    text-align: center;
  `};
`;

export const SubmitSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 5px;
`;
