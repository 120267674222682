/* eslint-disable react/no-multi-comp  */

import React from 'react';
import lodash from 'lodash';

import StarRating from '../StarRating';
import ContextDate from '../ContextDate';
import ProviderReviewResponseForm from '../ProviderReviewResponseForm';
import { ButtonTransparent } from '../Button';

import {
  SwitchableLayout,
  SwitchableLayoutItem,
  ProviderReviewCard,
  ProviderReviewResponseCard,
  ProviderReviewResponseHeading
} from './styles';

// TODO: need to have the review date added to the reviews serializer

const ReviewRatingAndDate = ({ date, score, stacked }) => (
  <SwitchableLayout stacked={stacked} distribute={!stacked}>
    <SwitchableLayoutItem>
      <StarRating review={score} />
    </SwitchableLayoutItem>
    <SwitchableLayoutItem>
      <ContextDate date={date} />
    </SwitchableLayoutItem>
  </SwitchableLayout>
);

ReviewRatingAndDate.defaultProps = {
  stacked: false
};

interface ReviewResponseProps {
  response: any,
  canRespond?: boolean,
};

class ReviewResponse extends React.Component<ReviewResponseProps> {
  static defaultProps = {
    response: null
  };

  multiLineResponse = comment => {
    if (comment) {
      // This breaks up multiline comments
      const splitOldComment = lodash.split(comment, '\n');
      const multiLineComment = lodash.map(splitOldComment, (item, index) => (
        <span key={index}>
          <p dangerouslySetInnerHTML={{ __html: item }} />
          <br />
        </span>
      ));
      return multiLineComment;
    }
    return comment;
  };

  render() {
    const {
      response: { updated, comment }
    } = this.props;
    return (
      <ProviderReviewResponseCard>
        <SwitchableLayout distribute>
          <SwitchableLayoutItem>
            <ProviderReviewResponseHeading>
              {"Provider's response"}
            </ProviderReviewResponseHeading>
          </SwitchableLayoutItem>
          <SwitchableLayoutItem>
            <ContextDate date={updated} />
          </SwitchableLayoutItem>
        </SwitchableLayout>
        <p>{this.multiLineResponse(comment)}</p>
      </ProviderReviewResponseCard>
    );
  }
}

const ReviewCardContentPublic = ({ score, date, reviewText }) => (
  <div>
    <ReviewRatingAndDate score={score} date={date} />
    <p dangerouslySetInnerHTML={{ __html: reviewText }} />
  </div>
);

ReviewCardContentPublic.defaultProps = {
  reviewText: ''
};

const ReviewCardContentStaff = ({
  score,
  date,
  reviewText,
  handleClick,
  showButton
}) => (
  <div>
    {reviewText ? <p dangerouslySetInnerHTML={{ __html: reviewText }} /> : null}
    <SwitchableLayout distribute>
      <SwitchableLayoutItem>
        <ReviewRatingAndDate stacked score={score} date={date} />
      </SwitchableLayoutItem>
      <SwitchableLayoutItem>
        {showButton && (
          <ButtonTransparent onClick={handleClick}>Respond</ButtonTransparent>
        )}
      </SwitchableLayoutItem>
    </SwitchableLayout>
  </div>
);

ReviewCardContentStaff.defaultProps = {
  reviewText: ''
};

interface ProviderReviewProps {
  response: any,
  canRespond?: boolean,
  isReplying?: boolean,
  total_score: number,
  date_published: string,
  comment?: string,
  id: number,
  onSubmit?: () => void,
  createReviewResponse?: (...args: any[]) => any,
  deleteReviewResponse?: (...args: any[]) => any,
};

interface ProviderReviewState {
  isReplying?: boolean,
};

class ProviderReview extends React.Component<ProviderReviewProps, ProviderReviewState> {
  static defaultProps = {
    comment: '',
    response: null,
    canRespond: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isReplying: !!props.response
    };
  }

  get showReply() {
    return !!this.props.response && !this.props.canRespond;
  }

  get showProviderResponseForm() {
    return (
      this.props.canRespond && (!!this.props.response || this.state.isReplying)
    );
  }

  renderReviewCardContent = () => {
    const {
      canRespond,
      total_score: score,
      date_published: date,
      comment,
      response
    } = this.props;
    const { isReplying } = this.state;
    if (canRespond) {
      return (
        <ReviewCardContentStaff
          score={score}
          date={date}
          reviewText={comment}
          handleClick={() => this.setState({ isReplying: true })}
          showButton={!this.showProviderResponseForm}
        />
      );
    }
    return (
      <ReviewCardContentPublic
        score={this.props.total_score}
        date={this.props.date_published}
        reviewText={this.props.comment}
      />
    );
  };

  render() {
    const {
      response,
      onSubmit,
      id,
      createReviewResponse,
      deleteReviewResponse
    } = this.props;
    return (
      <div>
        <ProviderReviewCard showResponse={!!response}>
          {this.renderReviewCardContent()}
        </ProviderReviewCard>
        {this.showReply && <ReviewResponse canRespond response={response} />}
        {this.showProviderResponseForm && (
          <ProviderReviewResponseForm
            id={id}
            createReviewResponse={createReviewResponse}
            deleteReviewResponse={deleteReviewResponse}
            form={`provider_review_response_${id}`}
            onSubmit={onSubmit}
            onRemove={() => {
              this.setState({ isReplying: false });
            }}
            comment={response && response.comment}
            updated={response && response.updated}
            isReplying={this.state.isReplying}
          />
        )}
      </div>
    );
  }
}

export default ProviderReview;
