import { decode } from 'jsonwebtoken';
import { AgeGroup, FundingType, Postcode, Provider, Service, ServiceArea } from '../state/types';
import { isPopulated } from '../util/isNil';

// this function is used to convert provider service IDs into services
export const resolveProviderServices = <T extends Provider>(
  services: Service[] | null | undefined,
  provider: T | {} | null | undefined,
): (Omit<T, 'services'> & {services: Service[]}) | {} => {
  if (!services || !isPopulated(provider)) {
    return {};
  }
  const remapped = provider.services
    ? provider.services.map(s => services.find(service => service.id == s)).filter(Boolean)
    : [];
  return {
    ...provider,
    services: remapped
  };
};

// this function is used to convert provider service IDs into service areas
export const resolveProviderServiceAreas = <T extends Provider>(
  services: Service[] | null | undefined,
  serviceAreas: ServiceArea[] | null | undefined,
  provider: T | {} | null | undefined,
): (T & {serviceAreas: ServiceArea[]}) | {} => {
  if (!services || !serviceAreas || !isPopulated(provider)) {
    return {};
  }
  const providerServices = services.filter(({id}) => provider.services.includes(id));
  const providerServiceAreaIds = providerServices.map(({service_area}) => service_area);
  const providerServiceAreas = serviceAreas.filter(({id}) => providerServiceAreaIds.includes(id));
  return {...provider, serviceAreas: providerServiceAreas};
};

export const resolveProviderAgeGroups = <T extends Provider>(
  ageGroups: {[k: string]: AgeGroup} | null | undefined,
  provider: T | {} | null | undefined,
): (Omit<T, 'age_groups'> & {age_groups: AgeGroup[]}) | {} => {
  if (!ageGroups || !isPopulated(provider)) {
    return {};
  }
  const remapped = provider.age_groups
    ? provider.age_groups.map(a => ageGroups[a]).filter(Boolean)
    : {};
  return {
    ...provider,
    age_groups: remapped
  };
};

export const resolveProviderFundingTypes = <T extends Provider>(
  fundingTypes: {[k: string]: FundingType} | null | undefined,
  provider: T | {} | null | undefined,
): (Omit<T, 'funding_types'> & {funding_types: FundingType[]}) | {} => {
  if (!fundingTypes || !isPopulated(provider)) {
    return {};
  }
  const remapped = provider.funding_types
    ? provider.funding_types.map(f => fundingTypes[f]).filter(Boolean)
    : {};
  return {
    ...provider,
    funding_types: remapped
  };
};

export const resolveProviderPostcodes = <T extends Provider>(
  postcodes: {[k: string]: Postcode} | null | undefined,
  provider: T | {} | null | undefined,
): (Omit<T, 'postcodes'> & {postcodes: Postcode[]}) | {} => {
  if (!postcodes || !isPopulated(provider)) {
    return {};
  }
  const remapped = provider.postcodes
    ? provider.postcodes.map(p => postcodes[p])
    : {};
  return {
    ...provider,
    postcodes: remapped
  };
};

export const decodeAccessToken = jwt => decode(jwt);

export const formatError = (code, message, data = null) => ({
  statusCode: code,
  message,
  data
});

export function arrayToHashMap(arr, objKey) {
  return arr.reduce((elems, curr) => {
    // get the key from the current object
    const key = curr[objKey];
    return {
      ...elems,
      [key]: curr
    };
  }, {});
}

export function objectToArray(obj) {
  return Object.keys(obj).map(key => obj[key]);
}

// This function is used to map postcode detail list to a postcode string
// e.g. 3053 - Carlton, Carlton North
export function postcodeDetailText(postcodesDetailList) {
  if (postcodesDetailList && postcodesDetailList.code && postcodesDetailList.labels) {
    const code = `${postcodesDetailList.code}`;
    const labels = ` ${postcodesDetailList.labels} `;
    return labels + '(' + code + ')';
  }
  return null;
}

export function postcodeExactDetailText(suburb, postcode, postcodesDetailList) {
  if (suburb && postcode){
    const code = `${postcode}`;
    const labels = ` ${suburb} `;
    return labels + '(' + code + ')';
  }
  else if (postcodesDetailList && postcodesDetailList.code && postcodesDetailList.labels) {
    const code = `${postcodesDetailList.code}`;
    const labels = ` ${postcodesDetailList.labels} `;
    return labels + '(' + code + ')';
  }
  return null;
}

export function getPostcodeSuburb(postcodesDetailList) {
  if (postcodesDetailList && postcodesDetailList.code && postcodesDetailList.labels) {
    const labels = postcodesDetailList.labels;
    return labels;
  }
  return null;
}

const transforms = {
  resolveProviderServices,
  arrayToHashMap,
  objectToArray
};

export default transforms;
