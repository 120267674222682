import React from 'react';
import { find } from 'lodash';

import { DeltaHeading } from '../../../../components/Typography';
import Theme from '../../../../components/Theme';
import {
  themeLightGrey,
  themeSecondaryTransparent,
} from '../../../../styles/constants';
import DisabilityIcon from './SC-child-wheelchair.svg';
import HomeCareIcon from './elderly-man-beard.svg';
import IconClientServiceCircle from './image-client-service-circle.svg';
import MedicareIcon from './mobile-phone-medicare.svg';
import NoProviderFoundForm from '../NoProviderFoundForm';
import Breakpoint from '../../../../components/Breakpoint';

import {
  ImageContainer,
  InformationSection,
  ButtonSection,
  Card,
  ButtonsSection,
  CardInfoText,
  CallLink,
  MainHeaderSection,
  HeadingBanner,
  HeadingMessage,
  FormHeaderSection,
  FormDataSection,
  MedicareInformationSection,
  MedicareImageContainer,
  FormBetaHeaderHeading,
  ClientServiceCircleIcon,
  FormSection,
} from './style';

import {
  HeaderTextPurple,
  HeaderText,
} from '../../../Static/components/ComplaintsAndFeedbackForm/style';

const SupportButtons = ({ text, href }) => (
  <ButtonsSection>
    <Card>
      <CallLink href={href}>
        <CardInfoText>
          <b>{text}</b>
        </CardInfoText>
      </CallLink>
    </Card>
  </ButtonsSection>
);

const DisabilitySection = () => (
  <Theme theme={themeLightGrey}>
    <InformationSection>
      <ImageContainer>
        <img
          src={DisabilityIcon}
          alt="Find NDIS and Disability services and providers"
        />
      </ImageContainer>
      <div>
        <DeltaHeading>Disability and NDIS searches</DeltaHeading>
        <p>
          The{' '}
          <a
            href="https://www.ndis.gov.au/understanding"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            National Disability Insurance Scheme (NDIS)
          </a>{' '}
          is the way of providing support to Australians with disability, their
          families and carers. The NDIS supports people under the age of 65 with
          a permanent and significant disability with the reasonable and
          necessary supports they need to live an ordinary life.
        </p>
        <ButtonSection>
          <SupportButtons
            text="Find out about NDIS funding"
            href="/funding-information/national-disability-insurance-scheme"
          />
        </ButtonSection>
      </div>
    </InformationSection>
  </Theme>
);

const HomeCareSection = () => (
  <Theme theme={themeLightGrey}>
    <Breakpoint>
      {(breakpoint) => (
        <InformationSection>
          {breakpoint === 'desktop' ? (
            <>
              <div>
                <DeltaHeading>
                  Aged care and Home Care package searches
                </DeltaHeading>
                <p>
                  Aged care support is commonly referred to as home care and is
                  available for people who are over 65 years old.
                </p>{' '}
                <br />
                <p>
                  The two home care funding streams available are the{' '}
                  <a
                    href="https://www.myagedcare.gov.au/help-at-home/commonwealth-home-support-programme"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Commonwealth Home Support Programme
                  </a>{' '}
                  (CHSP) and{' '}
                  <a
                    href="https://www.myagedcare.gov.au/help-at-home/home-care-packages"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Home Care Package
                  </a>{' '}
                  (HCP).
                </p>
                <ButtonSection>
                  <SupportButtons
                    text="Find out about HCP funding"
                    href="/funding-information/home-care-package-funding"
                  />
                </ButtonSection>
              </div>
              <ImageContainer>
                <img
                  src={HomeCareIcon}
                  alt="Find Age care and home care package services and providers"
                />
              </ImageContainer>
            </>
          ) : (
            <>
              <ImageContainer>
                <img
                  src={HomeCareIcon}
                  alt="Find Age care and home care package services and providers"
                />
              </ImageContainer>
              <div>
                <DeltaHeading>
                  Aged care and Home Care package searches
                </DeltaHeading>
                <p>
                  Aged care support is commonly referred to as home care and is
                  available for people who are over 65 years old.
                </p>{' '}
                <br />
                <p>
                  The two home care funding streams available are the{' '}
                  <a
                    href="https://www.myagedcare.gov.au/help-at-home/commonwealth-home-support-programme"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Commonwealth Home Support Programme
                  </a>{' '}
                  (CHSP) and{' '}
                  <a
                    href="https://www.myagedcare.gov.au/help-at-home/home-care-packages"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Home Care Package
                  </a>{' '}
                  (HCP).
                </p>
                <ButtonSection>
                  <SupportButtons
                    text="Find out about HCP funding"
                    href="/funding-information/home-care-package-funding"
                  />
                </ButtonSection>
              </div>
            </>
          )}
        </InformationSection>
      )}
    </Breakpoint>
    <InformationSection />
  </Theme>
);

const PrivateFundingSection = () => {
  const openChat = (e) => {
    e.preventDefault();
    const chatButton = document.getElementById('esw-fab');
    if (chatButton) {
      chatButton.click();
    }
  };

  return (
    <Theme theme={themeLightGrey}>
      <MedicareInformationSection>
        <MedicareImageContainer>
          <img
            src={MedicareIcon}
            alt="Find NDIS and Disability services and providers"
          />
        </MedicareImageContainer>
        <div>
          <DeltaHeading>Medicare and Private Health Insurance</DeltaHeading>
          <p>
            We can help with <b>Autism and ADHD Assessment</b> services under
            Medicare or if you have Private Health Insurance.
          </p>
          <ButtonSection>
            <SupportButtons text="Call now" href="tel:+61485972676" />
            <SupportButtons text="Live chat" href="#" onClick={openChat} />
          </ButtonSection>
        </div>
      </MedicareInformationSection>
    </Theme>
  );
};

const CSFormSection = (props) => {
  const {
    filters,
    ageGroups,
    fundingTypes,
    serviceAreas,
    setSubmitted,
    createNoProviderFoundContactRequest,
  } = props;

  const selectedServiceArea = find(
    serviceAreas,
    (serviceArea) => serviceArea.name === filters.service_areas_list,
  );

  function onSubmit(formData) {
    const submitData = Object.assign({}, { ...formData });
    submitData.service_required = selectedServiceArea.id;
    createNoProviderFoundContactRequest(submitData)
      .unwrap()
      .then(async (res) => res)
      .then(() => {
        setSubmitted(true);
      });
  }

  return (
    <Theme theme={themeLightGrey}>
      <FormDataSection>
        <FormHeaderSection>
          <ClientServiceCircleIcon>
            <img src={IconClientServiceCircle} alt="Karista Client Service" />
          </ClientServiceCircleIcon>
          <div>
            <FormBetaHeaderHeading>Tell us more</FormBetaHeaderHeading>
            <HeaderText>
              Our Client Services team are experienced in finding and connecting
              people to supports with availability. Complete the form below to
              tell us more about what you are looking for:
            </HeaderText>
          </div>
        </FormHeaderSection>
        <FormSection>
          <HeaderTextPurple>
            <b>Complete this form</b>
          </HeaderTextPurple>
          <HeaderText>
            Please answer these questions so we can assist you in finding
            services:
          </HeaderText>
          <NoProviderFoundForm
            onSubmit={onSubmit}
            filters={filters}
            ageGroups={ageGroups}
            fundingTypes={fundingTypes}
            serviceAreas={serviceAreas}
            setSubmitted={setSubmitted}
          />
        </FormSection>
      </FormDataSection>
    </Theme>
  );
};

const Empty = (props) => {
  const {
    filters,
    ageGroups,
    fundingTypes,
    serviceAreas,
    setSubmitted,
    createNoProviderFoundContactRequest,
  } = props;

  return (
    <Theme theme={themeSecondaryTransparent}>
      <MainHeaderSection>
        <HeadingBanner>
          We've been unable to find services or providers matching your search
          criteria.
        </HeadingBanner>
        <HeadingMessage>
          Let's see how else we might be able to help!
        </HeadingMessage>
      </MainHeaderSection>
      <DisabilitySection />
      <br />
      <HomeCareSection />
      <br />
      <PrivateFundingSection />
      <br />
      <CSFormSection
        filters={filters}
        ageGroups={ageGroups}
        fundingTypes={fundingTypes}
        serviceAreas={serviceAreas}
        setSubmitted={setSubmitted}
        createNoProviderFoundContactRequest={
          createNoProviderFoundContactRequest
        }
      />
    </Theme>
  );
};

export default Empty;
