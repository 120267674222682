import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import Page from '../../components/Page';
import Section from '../../components/Section';
import Theme from '../../components/Theme';
import { themeSecondary, themeLightGrey, colors } from '../../styles/constants';
import HCPFundingImage from './FundingImages/elderly-man-beard.svg';
import NDISFundingImage from './FundingImages/SC-child-wheelchair.svg';
import IconClientServiceCircle from '../Static/components/HowCanWeHelp/icon-client-services-circle.svg';

import {
  NDISHeaderSection,
  ListItem,
  List,
  SupportCategorySection,
  NDISFAQSection,
  FundingInfoText,
  CoreSupportIconContainer,
  FundingImageIcon,
  NDISClientServiceCircleIcon,
  NDISFAQParagraph,
  FundingInfoTextPurple,
  FundingInfoTextBlack,
  FundingSupportButtonsSection,
  HowWeCanHelpSection,
  NDISOtherText,
  SupportCategoryHeading,
  CoreSupportMobileIcon,
  CoreSupportMobileIconContainer,
  HowWeCanHelpDiv,
  FundingCardInfoText,
  FundingHelpSection,
  FundingButtonLink,
} from './style';

import { DeltaHeading, AlphaHeading } from '../../components/Typography';

import { Card, CallLink } from '../../apps/Providers/containers/styles';

const SupportButtonsNDIS = () => (
  <FundingSupportButtonsSection>
    <Card>
      <CallLink href="/funding-information/national-disability-insurance-scheme">
        <FundingCardInfoText>
          <b>Find out more</b>
        </FundingCardInfoText>
      </CallLink>
    </Card>
  </FundingSupportButtonsSection>
);

const SupportButtonsHCP = () => (
  <FundingSupportButtonsSection>
    <Card>
      <CallLink href="/funding-information/home-care-package-funding">
        <FundingCardInfoText>
          <b>Find out more</b>
        </FundingCardInfoText>
      </CallLink>
    </Card>
  </FundingSupportButtonsSection>
);

const DisabilityFunding = () => (
  <Theme theme={themeLightGrey}>
    <div>
      {window.innerWidth > 768 ? (
        <>
          <SupportCategorySection>
            <SupportCategoryHeading>
              <b>Disability Funding</b>
            </SupportCategoryHeading>
          </SupportCategorySection>
          <NDISFAQSection>
            <FundingInfoTextPurple>
              <b>
                The{' '}
                <a
                  href="https://www.ndis.gov.au"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National Disability Insurance Scheme (NDIS)
                </a>{' '}
                is the way of providing support to Australians with disability,
                their families and carers. The NDIS supports people under the
                age of 65 with a permanent and significant disability with the
                reasonable and necessary supports they need to live an ordinary
                life.{' '}
              </b>
            </FundingInfoTextPurple>
            <FundingInfoTextBlack>
              The NDIS helps people with disability to:
            </FundingInfoTextBlack>
            <br />
            <List style={{ width: '460px', color: colors.black }}>
              <ListItem>
                <b>Access mainstream services and supports -</b> services that
                are available for all Australians from people like doctors or
                teachers through the health and education systems. It also
                covers areas like public housing and the justice and aged care
                systems.
              </ListItem>
              <ListItem>
                <b>Access community services and support -</b> activities and
                services that are available to everyone in a community, such as
                sports clubs, community groups, libraries or charitie
              </ListItem>
              <ListItem>
                <b>Maintain informal support arrangements -</b> to help people
                get assistance from their family and friends.
              </ListItem>
              <ListItem>
                <b>Receive reasonable and necessary funded support -</b> This
                means they are related to a person's disability and are required
                for them to live an ordinary life and achieve their goals
              </ListItem>
            </List>
            <CoreSupportIconContainer>
              <FundingImageIcon
                src={NDISFundingImage}
                alt="Learn about the National Disability Insurance Scheme (NDIS) and how its funding can provide essential support and benefits for people with disabilities."
              />
            </CoreSupportIconContainer>
            <br />
            <FundingInfoTextBlack>
              Assistance from the NDIS is not means tested and has no impact on
              income support such as the Disability Support Pension and Carers
              Allowance.
            </FundingInfoTextBlack>
            <FundingInfoTextBlack>
              See the{' '}
              <a
                href="https://www.ndis.gov.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                NDIS website
              </a>{' '}
              for more details.
            </FundingInfoTextBlack>
            <SupportButtonsNDIS />
          </NDISFAQSection>
        </>
      ) : (
        <>
          <CoreSupportMobileIconContainer>
            <CoreSupportMobileIcon
              src={NDISFundingImage}
              alt="Learn about the National Disability Insurance Scheme (NDIS) and how its funding can provide essential support and benefits for people with disabilities."
            />
          </CoreSupportMobileIconContainer>
          <SupportCategorySection>
            <SupportCategoryHeading>
              <b>Disability Funding</b>
            </SupportCategoryHeading>
          </SupportCategorySection>
          <NDISFAQSection>
            <FundingInfoTextPurple>
              <b>
                The{' '}
                <a
                  href="https://www.ndis.gov.au"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National Disability Insurance Scheme (NDIS)
                </a>{' '}
                is the way of providing support to Australians with disability,
                their families and carers. The NDIS supports people under the
                age of 65 with a permanent and significant disability with the
                reasonable and necessary supports they need to live an ordinary
                life.{' '}
              </b>
            </FundingInfoTextPurple>
            <FundingInfoTextBlack>
              The NDIS helps people with disability to:
            </FundingInfoTextBlack>
            <br />
            <List style={{ width: '280px', color: colors.black }}>
              <ListItem>
                <b>Access mainstream services and supports -</b> services that
                are available for all Australians from people like doctors or
                teachers through the health and education systems. It also
                covers areas like public housing and the justice and aged care
                systems.
              </ListItem>
              <ListItem>
                <b>Access community services and support -</b> activities and
                services that are available to everyone in a community, such as
                sports clubs, community groups, libraries or charitie
              </ListItem>
              <ListItem>
                <b>Maintain informal support arrangements -</b> to help people
                get assistance from their family and friends.
              </ListItem>
              <ListItem>
                <b>Receive reasonable and necessary funded support -</b> This
                means they are related to a person's disability and are required
                for them to live an ordinary life and achieve their goals
              </ListItem>
            </List>
            <br />
            <FundingInfoTextBlack>
              Assistance from the NDIS is not means tested and has no impact on
              income support such as the Disability Support Pension and Carers
              Allowance.
            </FundingInfoTextBlack>
            <FundingInfoTextBlack>
              See the{' '}
              <a
                href="https://www.ndis.gov.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                NDIS website
              </a>{' '}
              for more details.
            </FundingInfoTextBlack>
            <SupportButtonsNDIS />
            <br />
          </NDISFAQSection>
        </>
      )}
    </div>
  </Theme>
);

const AgeCareFunding = () => (
  <Theme theme={themeLightGrey}>
    <div>
      {window.innerWidth > 768 ? (
        <>
          <SupportCategorySection>
            <SupportCategoryHeading>
              <b>Aged Care funding</b>
            </SupportCategoryHeading>
          </SupportCategorySection>
          <NDISFAQSection>
            <FundingInfoTextPurple>
              <b>
                Aged care support is commonly referred to as home care and is
                available for people who are over 65 years old.{' '}
              </b>
            </FundingInfoTextPurple>
            <FundingInfoTextBlack>
              There are two main home care funding streams available:
            </FundingInfoTextBlack>
            <br />
            <List style={{ width: '460px', color: colors.black }}>
              <ListItem>
                <b>
                  The Commonwealth Home Support Programme (
                  <a
                    href="https://www.myagedcare.gov.au/help-at-home/commonwealth-home-support-programme"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CHSP
                  </a>
                  )
                </b>{' '}
                <br />
                This is designed to help older Australians remain independent in
                their own homes and communities for longer. Funding covers
                services to help with daily tasks and can even help carers with
                planned respite.
              </ListItem>
              <br />
              <ListItem>
                <b>
                  Home Care Package (
                  <a
                    href="https://www.myagedcare.gov.au/help-at-home/home-care-packages"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    HCP
                  </a>
                  )
                </b>{' '}
                <br />
                Home Care Packages offer choice and flexibility for recipients.
                Packages are tailored to meet a range of care needs and are
                offered at four levels depending on the amount of support
                required. There are four levels ranging from basic through high
                level care.
              </ListItem>
            </List>
            <CoreSupportIconContainer>
              <FundingImageIcon
                src={HCPFundingImage}
                alt="Discover what a Home Care Package (HCP) is and how it supports independent living at home. Learn the steps to obtain your HCP today."
              />
            </CoreSupportIconContainer>
            <br />
            <FundingInfoTextBlack>
              With the introduction of{' '}
              <a
                href="https://www.myagedcare.gov.au/help-at-home/commonwealth-home-support-programme"
                target="_blank"
                rel="noopener noreferrer"
              >
                Consumer Directed Care (CDC)
              </a>{' '}
              Home Care Packages are now controlled by the recipient rather than
              the service provider. This means that you can compare providers to
              find one that best meets your individual needs. Today, it is much
              easier to change providers. If you make a change, the funding for
              your package will follow you to your new provider.
            </FundingInfoTextBlack>
            <FundingInfoTextBlack>
              Assessments for both the CHSP and Home Care Package are
              facilitated by the Australian Government through{' '}
              <a
                href="https://www.myagedcare.gov.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                MyAgedCare
              </a>
              . For the Commonwealth Home Support Programme, you may need a home
              support assessment. Home Care Packages are planned by an Aged Care
              Assessment Team (ACAT) or Aged Care Assessment Service (ACAS) in
              Victoria.
            </FundingInfoTextBlack>
            <br />
            <FundingInfoTextBlack>
              <b>Other available funding programs include:</b>
            </FundingInfoTextBlack>
            <List style={{ width: '575px', color: colors.black }}>
              <ListItem>
                Department of Veteran's Affairs (
                <a
                  href="https://www.myagedcare.gov.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DVA
                </a>
                )
              </ListItem>
              <ListItem>
                National Respite for Carers (
                <a
                  href="https://www.myagedcare.gov.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NRCP
                </a>
                ) or Consumer Directed Respite Care (
                <a
                  href="https://www.myagedcare.gov.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CDCR
                </a>
                )
              </ListItem>
            </List>
            <SupportButtonsHCP />
          </NDISFAQSection>
        </>
      ) : (
        <>
          <CoreSupportMobileIconContainer>
            <CoreSupportMobileIcon
              src={HCPFundingImage}
              alt="Discover what a Home Care Package (HCP) is and how it supports independent living at home. Learn the steps to obtain your HCP today."
              style={{ marginTop: '20px' }}
            />
          </CoreSupportMobileIconContainer>
          <SupportCategorySection>
            <SupportCategoryHeading>
              <b>Aged Care funding</b>
            </SupportCategoryHeading>
          </SupportCategorySection>
          <NDISFAQSection>
            <FundingInfoTextPurple>
              <b>
                Aged care support is commonly referred to as home care and is
                available for people who are over 65 years old.{' '}
              </b>
            </FundingInfoTextPurple>
            <FundingInfoTextBlack>
              There are two main home care funding streams available:
            </FundingInfoTextBlack>
            <br />
            <List style={{ width: '280px', color: colors.black }}>
              <ListItem>
                <b>
                  The Commonwealth Home Support Programme (
                  <a
                    href="https://www.myagedcare.gov.au/help-at-home/commonwealth-home-support-programme"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CHSP
                  </a>
                  )
                </b>{' '}
                <br />
                This is designed to help older Australians remain independent in
                their own homes and communities for longer. Funding covers
                services to help with daily tasks and can even help carers with
                planned respite.
              </ListItem>
              <ListItem>
                <b>
                  Home Care Package (
                  <a
                    href="https://www.myagedcare.gov.au/help-at-home/home-care-packages"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    HCP
                  </a>
                  )
                </b>{' '}
                <br />
                Home Care Packages offer choice and flexibility for recipients.
                Packages are tailored to meet a range of care needs and are
                offered at four levels depending on the amount of support
                required. There are four levels ranging from basic through high
                level care.
              </ListItem>
            </List>
            <br />
            <FundingInfoText style={{ color: colors.black, marginTop: '10px' }}>
              With the introduction of{' '}
              <a
                href="https://www.myagedcare.gov.au/help-at-home/commonwealth-home-support-programme"
                target="_blank"
                rel="noopener noreferrer"
              >
                Consumer Directed Care (CDC)
              </a>{' '}
              Home Care Packages are now controlled by the recipient rather than
              the service provider. This means that you can compare providers to
              find one that best meets your individual needs. Today, it is much
              easier to change providers. If you make a change, the funding for
              your package will follow you to your new provider.
            </FundingInfoText>
            <br />
            <FundingInfoText style={{ color: colors.black, marginTop: '10px' }}>
              Assessments for both the CHSP and Home Care Package are
              facilitated by the Australian Government through{' '}
              <a
                href="https://www.myagedcare.gov.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                MyAgedCare
              </a>
              . For the Commonwealth Home Support Programme, you may need a home
              support assessment. Home Care Packages are planned by an Aged Care
              Assessment Team (ACAT) or Aged Care Assessment Service (ACAS) in
              Victoria.
            </FundingInfoText>
            <br />
            <FundingInfoTextBlack>
              <b>Other available funding programs include:</b>
            </FundingInfoTextBlack>
            <List style={{ width: '280px', color: colors.black }}>
              <ListItem>
                Department of Veteran's Affairs (
                <a
                  href="https://www.myagedcare.gov.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DVA
                </a>
                )
              </ListItem>
              <ListItem>
                National Respite for Carers (
                <a
                  href="https://www.myagedcare.gov.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NRCP
                </a>
                ) or Consumer Directed Respite Care (
                <a
                  href="https://www.myagedcare.gov.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CDCR
                </a>
                )
              </ListItem>
            </List>
            <SupportButtonsHCP />
            <br />
          </NDISFAQSection>
        </>
      )}
    </div>
  </Theme>
);

const HelpSection = () => {
  const openChat = (e) => {
    e.preventDefault();
    const chatButton = document.getElementById('esw-fab');
    if (chatButton) {
      chatButton.click();
    }
  };

  return (
    <NDISHeaderSection>
      <NDISClientServiceCircleIcon>
        <img
          src={IconClientServiceCircle}
          alt="Karista is a platform that connects people with disability to NDIS registered providers"
        />
      </NDISClientServiceCircleIcon>
      <HowWeCanHelpDiv>
        <SupportCategorySection
          style={{ paddingRight: '10px', paddingLeft: '10px' }}
        >
          <NDISOtherText>
            <b>Karista can help</b>
          </NDISOtherText>
        </SupportCategorySection>
        <HowWeCanHelpSection>
          <FundingInfoText style={{ color: colors.black }}>
            Karista provides a free, independent service connecting you with
            disability and home care services, therapists and support workers
            based on your personal needs and goals.
          </FundingInfoText>
          <NDISFAQParagraph style={{ paddingTop: '10px' }}>
            Our Client Services team are experienced in finding and connecting
            NDIS and Home Care Package (HCP) participants to supports with
            availability.
          </NDISFAQParagraph>
          <NDISFAQParagraph style={{ paddingTop: '10px' }}>
            You can read more about us <a href="/about">here</a>.
          </NDISFAQParagraph>
        </HowWeCanHelpSection>
        <FundingHelpSection>
          <FundingButtonLink to="/" onClick={openChat}>
            <b>Chat with a team member</b>
          </FundingButtonLink>
          <FundingButtonLink to="/">
            <b>Search for services now</b>
          </FundingButtonLink>
        </FundingHelpSection>
      </HowWeCanHelpDiv>
    </NDISHeaderSection>
  );
};

function FundingData() {
  return (
    <Section>
      <AlphaHeading>Funding Information</AlphaHeading>
      <DeltaHeading>
        In Australia, the government provides funding to some people who have a
        disability as well as some people who are elderly and need assistance
        with daily living.
      </DeltaHeading>
      <DisabilityFunding />
      <br />
      <AgeCareFunding />
      <HelpSection />
    </Section>
  );
}

function FundingInformation() {
  return (
    <>
      <Helmet>
        <title>Understand NDIS Funding and Home Care Package Funding</title>
        <meta
          name="description"
          content="Learn about different funding options. Get detailed information on NDIS funding and Home Care Package funding to support your care needs effectively."
        />
      </Helmet>
      <Theme theme={themeSecondary}>
        <Navigation />
        <Page>
          <FundingData />
        </Page>
        <Footer />
      </Theme>
    </>
  );
}

export default FundingInformation;
