import React from 'react';
import styled from 'styled-components';
import InstagramIcon from './social_icon_instagram.svg';
import FacebookIcon from './social_icon_facebook.svg';
import LinkedinIcon from './social_icon_linkedin.svg';
import { colors } from '../../styles/constants';
import mediaQueries from '../../styles/mediaQueries';

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  a {
    margin: 0 8px;
  }
  svg {
    padding: 0 10px;
  }
  path {
    fill: ${colors.white};
  }
  margin-top: 20px;
  margin-right: 10px;
  padding-right: 10px;

  ${mediaQueries.mobile`
    justify-content: left;
    margin-left: 20px;
  `};
`;

const Links = [
  {
    icon: <img src={InstagramIcon} alt="Instagram" />,
    key: 'Instagram',
    link: 'https://www.instagram.com/karistacare/',
  },
  {
    icon: <img src={FacebookIcon} alt="Facebook" />,
    key: 'Facebook',
    link: 'https://www.facebook.com/Karista-413403892326876/',
  },
  {
    icon: <img src={LinkedinIcon} alt="LinkedIn" />,
    key: 'Linkedin',
    link: 'https://www.linkedin.com/company/18157963/',
  },
];

const SocialLink = () => (
  <SocialContainer>
    {Links.map(({ icon, key, link }) => (
      <a key={key} href={link} target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    ))}
  </SocialContainer>
);

export default SocialLink;
