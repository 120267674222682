import styled from 'styled-components';
import Section from '../../../../components/Section';
import {
  GammaHeading,
  AlphaHeading,
  BetaHeading
} from '../../../../components/Typography';
import { colors } from '../../../../styles/constants';

const cardStackBreakpoint = '720px';

export const InformationSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
  }
`;

export const MainHeaderSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: column;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
  }
`;

export const MedicareInformationSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 96px;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    gap: 6px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 280px;
  width: 240px;
  padding: 20px;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 300px;
    height: 200px;
    align-self: center;
    margin-top: -30px;
  }
`;

export const MedicareImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 200px;
  width: 200px;
  padding: 16px;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 400px;
    height: 400px;
    align-self: center;
    margin-top: -120px;
    margin-bottom: -100px;
    padding: 4px;
  }
`;

export const ButtonSection = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const Card = styled.div`
  color: ${colors.orange};
  background: ${colors.orange};
  height: fit-content;
  min-width: fit-content;
  max-width: 230px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;

  &:hover {
    border: 1px solid ${colors.black};
  }

  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 20px;
  }
`;

export const ButtonsSection = styled.div`
  margin-right: 16px;
`;

export const CardInfoText = styled.span`
  color: ${colors.purple};
  padding: 5px;
  font-size: 18px;
`;

export const CallLink = styled.a`
  text-decoration: none;
`;

export const HeadingMessage = styled(GammaHeading)`
  font-size: 32px;
  color: ${colors.purple};
`;

export const HeadingBanner = styled(AlphaHeading)`
  font-size: 19px;
  color: ${colors.black};

  @media (max-width: ${cardStackBreakpoint}) {
    line-height: 26px;
  };

`;

export const FormHeaderSection = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
 
  @media (max-width: ${cardStackBreakpoint}) {
    width: auto;
    flex-direction: column;
  };
`;

export const FormDataSection = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const FormBetaHeaderHeading = styled(BetaHeading)`
  margin-bottom: 8px;
`;

export const ClientServiceCircleIcon = styled.div`
  margin-bottom: 12px;
  margin-right: 16px;

  @media (max-width: ${cardStackBreakpoint}) {
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
  }
`;

export const FormSection = styled(Section)`
  background: ${colors.white};
  height: fit-content;
  text-align: left;
  padding-top: 4%;
  margin-bottom: 24px;
  border-radius: 14px;
  border: 2px solid ${colors.orange};
  
  @media (max-width: ${cardStackBreakpoint}) {
    background: ${colors.lightGrey};
    margin-bottom: 4px;
    border: 0px;
    padding-left: 2px;
  }
`;