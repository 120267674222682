import styled from "styled-components";
import Section from "../../../../components/Section";
import { BetaHeading } from "../../../../components/Typography";
import {colors} from "../../../../styles/constants";

const cardStackBreakpoint = '720px';

export const HeaderSection = styled(Section)`
  text-align: center;
  padding: 4px;
`;

export const HeaderHeading = styled(BetaHeading)`
  color: ${colors.purple};
  margin-bottom: 8px;
  margin-top: 32px;
`;

export const Card = styled.div`
  color: ${colors.black};
  background: ${colors.lightGrey};
  width: 378px;
  height: 550px;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 10px;
  align-items: center;
  padding: 6%;
  
  @media (max-width: ${cardStackBreakpoint}) {
    padding: 20px 20px;
    width: 90%;
    max-width: 300px;
    height: fit-content;
  }
`;

export const OtherCard = styled.div`
  color: ${colors.black};
  background: ${colors.lightGrey};
  width: 378px;
  height: 410px;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 10px;
  align-items: center;
  padding: 6%;
  
  @media (max-width: ${cardStackBreakpoint}) {
    padding: 20px 20px;
    width: 90%;
    max-width: 300px;
    height: fit-content;
  }
`;

export const CardSpacer = styled.div`
  max-width: 480px;
  min-width: 200px;
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: 24px;
  @media (max-width: ${cardStackBreakpoint}) {
    &:not(:last-of-type) {
      margin-bottom: 48px;
    }
  }
`;

export const CardsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%;

  @media (max-width: ${cardStackBreakpoint}) {
    align-items: center;
    margin-left: 0px;
    padding-left: 0;
  }
`;

export const CardsInternalSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    margin-left: 0px;
  }
`;

export const BlackInfoText = styled.span`
  color: ${colors.black};
`;

export const CardIconContainer = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;

  @media (max-width: ${cardStackBreakpoint}) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

export const CardIcon = styled.img`
  width: 80px;
`;

export const CardInfoText = styled.p`
  padding: 5px;
`;
