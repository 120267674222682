import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/constants';
import calculateRem from '../../styles/calculateRem';

// copied from `Hero` because theming was hardcoded
const HeroContent = styled.div`
  max-width: ${calculateRem(720)};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const StyledHero = styled.section`
  background: ${colors.purple};
  padding-top: ${calculateRem(36)};
  padding-bottom: 0;
  padding-left: ${calculateRem(24)};
  padding-right: ${calculateRem(24)};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-content: center;
  ${(props) => ({ ...props.theme })};
`;

export const HeroImage = styled.img`
  height: auto;
  width: auto;
  margin: auto;
  max-height: 200px;
  margin-left: 50px;
`;

export const HeroRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-self: baseline;
  width: 100%;
  justify-content: flex-end;
`;
export const HeroColumn = styled(HeroRow)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Hero = ({ children }) => (
  <StyledHero>
    <HeroContent>{children}</HeroContent>
  </StyledHero>
);

export default Hero;
