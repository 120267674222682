import React from 'react';
import { useWatch } from 'react-hook-form';
import CheckboxForm from '../CheckboxFrom';
import styles from "./styles.module.css";

interface CheckboxOption {
  label: string;
  value: string;
}

interface CheckboxGroupProps {
  fieldOptions: CheckboxOption[];
  control: any; // react-hook-form control prop
  name: string;
  meta: {
    submitFailed: boolean;
    error: string;
  };
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ fieldOptions, control, name, meta }) => {

  const preferences = useWatch({
    control,
    name,
    defaultValue: [], // Default to an empty array
  });

  const isNoPreferenceSelected = preferences.includes("No preference");

  return (
    <div className={styles.checkboxGroup}>
      {fieldOptions.map(option => (
        <div
          key={option.value}
          className={isNoPreferenceSelected && option.value !== "No preference" ? styles.greyedOut : ''}
        >
        <CheckboxForm
          key={option.value}
          label={option.label}
          input={{
            type: 'checkbox',
            ...control.register(name, { required: false }), // Use required: false to prevent default "on" value
            value: option.value,
            disabled: isNoPreferenceSelected && option.value !== "No preference", // Disable other checkboxes if "No Preference" is selected
          }}
        />
        </div>
      ))}
      {meta.submitFailed && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>}
    </div>
  );
};

export default CheckboxGroup;
