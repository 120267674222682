import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';

import ProviderDetails from '../../../components/ProviderDetails';
import ProfileHeader from '../../../components/ProfileHeader';

import {
  resolveProviderServices,
  resolveProviderFundingTypes,
  resolveProviderAgeGroups
} from '../../../util/transforms';
import { AgeGroup, FundingType, Provider, Service } from '../../../state/types';
import { useGetProviderDetailsUsingSlugQuery } from '../../../services/karista';
import ProviderReviewsList from './provider_reviews';
import { reduceQueries } from '../../../util/rtk-query';
import karistaApi from '../../../services/karista';

const useInitialize = () => reduceQueries({
  serviceAreas: karistaApi.useGetAllServiceAreasQuery(),
  internalLinks: karistaApi.useGetAllInternalLinksQuery()
});

export interface DetailProps extends RouteComponentProps<{id?: string, slug?: string}> {
  services?: Service[],
  fundingTypes?: {[k: string]: FundingType},
  ageGroups?: {[k: string]: AgeGroup},
};

interface InnerDetailProps extends Omit<DetailProps, 'fundingTypes' | 'services' | 'ageGroups' | 'location'> {
  selectedProvider: Omit<Provider, 'services' | 'age_groups' | 'funding_types'> & {services: Service[], age_groups: AgeGroup[], funding_types: FundingType[]},
  isProviderError: boolean,
  isLoading: boolean,
};

const useDetailProps = (props: DetailProps): InnerDetailProps => {
  const {
    match: {
      params: { id: providerId, slug: providerSlug },
    },
    services,
    fundingTypes,
    ageGroups,
  } = props;

  // const {data: rawProvider, isError, isLoading} = useGetProviderDetailsQuery(parseInt(providerId as string));
  const {data: rawProvider, isError, isLoading} = useGetProviderDetailsUsingSlugQuery(providerSlug as string);
  let selectedProvider = resolveProviderServices(services, rawProvider);
  selectedProvider = resolveProviderFundingTypes(fundingTypes, selectedProvider);
  selectedProvider = resolveProviderAgeGroups(ageGroups, selectedProvider);
  return {
    ...props,
    // TODO: The below cast is only here because of the jumbling up of API types and transformed types that goes into
    // providers. I'll add a ticket to clean this up, then this can go.
    selectedProvider: selectedProvider as (Omit<Provider, 'services' | 'age_groups' | 'funding_types'> & {services: Service[], age_groups: AgeGroup[], funding_types: FundingType[]}),
    isProviderError: isError,
    isLoading,
  };
}

const Detail: React.FC<DetailProps> = props => {
  const { serviceAreas, internalLinks, isLoading: serviceAreaIsLoading } = useInitialize();
  const {
    selectedProvider: {
      services,
      funding_types: fundingTypes,
      age_groups: ageGroups,
      id: id,
      logo_url: logoUrl,
      overall_review: review,
      reviews_count: reviewsCount,
      is_active: isActive,
      url_slug: slug,
      ...providerRest
    },
    isProviderError,
    isLoading,
  } = useDetailProps(props);

  if (isProviderError) {
    window.location.href = "/"
  }

  // TODO: We should only need "isLoading".
  if (!id || isLoading) {
    return <div>Loading ...</div>;
  }

  const serviceAreaData = serviceAreas.data || [];
  const internalLinksData = internalLinks.data || [];

  const serviceAreaMap = serviceAreaData.reduce((acc, area) => {
    acc[area.id] = area.slug;
    return acc;
  }, {} as Record<number, string>);

  // Generate meta keywords from services and funding types
  const metaKeywords = [
    ...services.map(service => service.name.toLowerCase()),
    ...fundingTypes.map(fundingType => fundingType.code.toLowerCase())
  ].join(", ");

  return (
    <>
      <Helmet>
        <title>{`Connect with ${providerRest.name} via Karista`}</title>
        <meta name="description" content={`Read how ${providerRest.name} could help you achieve your goals and improve quality of life. Karista connects you with providers that meet your needs.`} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
      <ProfileHeader
        logoUrl={logoUrl}
        providerId={id}
        hasReviews={false}
        review={review}
        purple
        reviewsCount={reviewsCount}
        isActive={isActive}
        providerSlug={slug}
        {...providerRest}
      >
      <ProviderDetails
        {...providerRest}
        services={services}
        fundingTypes={fundingTypes}
        providerId={id}
        isActive={isActive}
        providerSlug={slug}
        serviceAreaMap={serviceAreaMap}
        internalLinksData={internalLinksData}
      />
      <ProviderReviewsList
      {...props}
      internalLinksData={internalLinksData}
      />
      </ProfileHeader>
  </>
  );
}

export default Detail;
