import styled from 'styled-components';
import { colors } from '../../../../styles/constants';
import { AlphaHeading, GammaHeading } from '../../../../components/Typography';

export const StyledAlphaHeading = styled(AlphaHeading)`
  color: ${colors.midLightPurple};
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 24px;
`;

export const StyledGammaHeading = styled(GammaHeading)`
  color: ${colors.midLightPurple};
  margin-bottom: 0px;
  margin-top: 24px;
`;

export const StyledContactText = styled.p`
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5;
  color: ${colors.midLightPurple};
  margin-top: 0.75em;
`;

export const StyledContactLink = styled.a`
  color: ${colors.black};
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 0.3em;
`;

export const CardHeading = styled(GammaHeading)`
  margin-bottom: 0px;
  margin-top: 0px;
  background: none;
`;

export const CardLead = styled.strong`
  font-size: inherit;
  line-height: 1.4;
  margin-bottom: 18px;
  display: block;
  font-weight: 400;
`

export const Columns = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 18px;
  flex-wrap: wrap;
  align-items: flex-start;
`

export const ToolkitColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Adjust the gap value as needed */
  justify-items: center;
  & > * {
    min-width: 300px;
    min-height: 200px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const VideoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Adjust the gap value as needed */
  justify-items: center;
  & > * {
    min-width: 300px; /* Adjust the minimum width as needed */
    min-height: 200px; /* Adjust the minimum height as needed */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.section`
  background-color: ${colors.lightGrey};
  padding: 18px;
  flex: 1;
`;

export const WideCard = styled(Card)`
  flex: 2;
  flex-basis: 100%;
`;

export const BulletList = styled.ul`
  margin-left: 20px;
  margin-bottom: 18px;
`

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`

export const StyledCaption = styled.span`
  text-align: start;
  font-size: small;
  line-height: initial;
  margin-bottom: 1em;
  font-weight: bold;
`
export const Thumbnail = styled.img`
  width: 100%;
  max-width: 200px;
  box-shadow: 0px 0px 5px rgb(0, 0, 0, 0.3);
  margin: 20px;
`