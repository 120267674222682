import styled from 'styled-components';

export default styled.section`
  padding-bottom: 0px;
  padding-top: 48px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  max-width: 720px;
  ${(props) => ({ ...props.theme })};
`;
