/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable consistent-return */

import React from 'react';
import { useForm} from "react-hook-form";
import Input from '../../../../components/InputComplaints';
import Button from '../../../../components/Button';
import Textarea from '../../../../components/Textarea';
import Checkbox from '../../../../components/Checkbox';
import ValidationError from '../../../../components/ValidationError';
import FormItem from '../../../../components/FormItem';
import styles from "../../../../styles/global.module.css"
import { isEmail } from 'validator';
import {isValidPhoneNumber} from "libphonenumber-js";

import {
    SubLabel,
    SubmitSection,
    PrivacyLabel
} from './style';

import {
  CenteredWithGutters,
  BtnContainer,
  RowContainer
} from '../../../../components/HCPAdditionalQuestionsForm/styles';

import {WhiteAnchor} from "../../../../components/Link";

// These types are for ease of managing form state, final
// data types for submission are below.
type FormValues = {
  complaint_first_name: string
  complaint_last_name: string
  complaint_email: string
  complaint_number: string
  complaint_provider: string
  complaint_details: string
  complaint_against_karista: boolean
}

// This is different from the form state values.
// The transformation happens on delegation to the submit handler.
export type ComplaintandFeedbackQuestionsSubmitData = {
    complaint_first_name: string
    complaint_last_name: string
    complaint_email: string
    complaint_number: string
    complaint_provider: string
    complaint_details: string
    complaint_against_karista: boolean
}
  

const ComplaintsAndFeedbackQuestions = (props) => {
  const {
    register,
    formState,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    setError,
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
        complaint_first_name: "",
        complaint_last_name: "",
        complaint_email: "",
        complaint_number: "",
        complaint_provider: "",
        complaint_details: "",
        complaint_against_karista: false
    }
  });
  const {errors} = formState;

  const submitForm = async (data: FormValues) => {
    try {
      await props.onSubmit({...data, complaint_first_name: data.complaint_first_name, complaint_last_name: data.complaint_last_name,
        complaint_email: data.complaint_email, complaint_number: data.complaint_number,
        complaint_provider: data.complaint_against_karista ? "Karista" : data.complaint_provider , complaint_details: data.complaint_details, complaint_against_karista: data.complaint_against_karista});
      reset({}, {keepValues: true});
    }
    catch (error) {
      console.error('Unexpected occurred:', error);
      setError('root', {message: 'Unexpected error'});
    }
  }

  return (
    <CenteredWithGutters>
      <form onSubmit={handleSubmit(submitForm)}>
      <RowContainer style={{ marginTop: '24px' }}>
        {window.innerWidth > 768 ? (
            <FormItem style={{ marginRight: '24px' }}>
              <SubLabel>Your first name*</SubLabel>
              <Input
                input={{type: "text", ...register("complaint_first_name", {required: true})}}
                meta={{submitFailed: !!errors.complaint_first_name, error: "First name is required"}}
              />
            </FormItem>
        ) :(
          <FormItem>
            <SubLabel>Your first name*</SubLabel>
            <Input
              input={{type: "text", ...register("complaint_first_name", {required: true})}}
              meta={{submitFailed: !!errors.complaint_first_name, error: "First name is required"}}
            />
          </FormItem>
        )}
        <FormItem>
          <SubLabel>Your surname*</SubLabel>
          <Input
            input={{type: "text", ...register("complaint_last_name", {required: true})}}
            meta={{submitFailed: !!errors.complaint_last_name, error: "Surname is required"}}
          />
        </FormItem>
        </RowContainer>
        <FormItem>
          <SubLabel>Email*</SubLabel>
          <Input
            input={{type: "text", ...register("complaint_email", {required: true, validate: (val) => isEmail(val)? undefined : "Invalid URL"})}}
            meta={{submitFailed: !!errors.complaint_email, error: "Please enter a valid email"}}
          />
        </FormItem>
        <FormItem>
          <SubLabel>Phone*</SubLabel>
          <Input className={styles.halfWidthDesktop}
            input={{type: "text", ...register("complaint_number", {required: true, validate: (val) => isValidPhoneNumber(val, "AU")})}}
            meta={{submitFailed: !!errors.complaint_number, error: "Please enter a valid phone number"}}
          />
        </FormItem>
        <FormItem>
          <SubLabel>Provider organisation name</SubLabel>
          <Input
            input={{type: "text", ...register("complaint_provider", {required: false})}}
          />
        </FormItem>
        <FormItem style={{display: 'flex', flexDirection: 'row'}}>
            <Checkbox
                input={{ type: "checkbox", ...register("complaint_against_karista", {required: false}) }}
            />
            <SubLabel>My feedback or complaint is about Karista</SubLabel>
        </FormItem>
        <FormItem>
          <SubLabel>Please give us as much detail as possible about your experience or issue:</SubLabel>
          <Textarea className={styles.fullWidth} style={{background: 'white'}}
            input={{type: "text", ...register("complaint_details", {required: false})}}
          />
        </FormItem>
        <SubmitSection>
            <PrivacyLabel>
                We value your privacy and are committed to keeping your information confidential. 
                The details you provide will not be shared with anyone outside of our organisation. 
                We will only share your information with a trusted provider if you give us explicit consent to do so.
            </PrivacyLabel>
            <BtnContainer>
                <Button disabled={formState.isSubmitting || formState.isSubmitSuccessful} fullWidth type="submit">
                    Submit
                </Button>
            </BtnContainer>
        </SubmitSection>
        {errors.root && (
          <CenteredWithGutters>
              <ValidationError meta={{submitFailed: true, error: "Sorry, there has been a system error."}} >
                &nbsp;If this error persists, please <WhiteAnchor href="mailto:info@karista.com.au?Subject=I can't submit my request">contact us</WhiteAnchor>.
              </ValidationError>
          </CenteredWithGutters>
        )}
      </form>
    </CenteredWithGutters>
  );
}

export default ComplaintsAndFeedbackQuestions;
