/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable consistent-return */

import React, {useState} from 'react';
import { useForm} from "react-hook-form";
import { RouteComponentProps } from 'react-router';
import { concat } from 'lodash';
import Button from '../Button';
import Input from '../InputComplaints';
import Select from '../Select';
import MultiSelect from '../MutliSelect';
import CheckboxForm from '../CheckboxFrom';
import CheckboxGroup from '../CheckboxGroup';
import ValidationError from '../ValidationError';
import FormItem from '../FormItem';
import { useGetQuoteDetailFormQuery } from '../../services/karista';
import {
  CenteredWithGutters,
  StyledLabel,
  BtnContainer,
  SubLabel,
  RowContainer,
  SubmitSection,
  PrivacyLabel,
  RadioButtonContainer,
  SubmitLabel
} from './styles';
import {ServiceArea, Diagnosis} from "../../state";
import {WhiteAnchor} from "../Link";

interface QuoteAdditionalFormProps {
  onSubmit: (data: QuoteAdittionalQuestionsSubmitData) => Promise<void>,
  serviceAreas?: ServiceArea[]
  diagnosis?: Diagnosis[]
  selectedService?: ServiceArea
  quoteId?: string
}

// These types are for ease of managing form state, final
// data types for submission are below.
type FormValues = {
  participant_first_name: string
  participant_last_name: string
  participant_relation: string
  diagnosis: number[]
  additional_services: number[]
  hcp_service_areas: number[]
  terms: boolean
  preferencesCheckbox: boolean
  gender_preference: string
  language_preference: string
  day_preference: string[]
  time_preference: string[]
  additional_preference: string
  plan_management: string
  participant_age: string
  behaviour_relevance: string
  service_frequency: string
  additional_needs: string
  participant_goals: string
  consent_to_send_ndis_plan: boolean
  ndis_plan_file: File
}

// This is different from the form state values.
// The transformation happens on delegation to the submit handler.
export type QuoteAdittionalQuestionsSubmitData = {
  participant_first_name: string
  participant_last_name: string
  participant_relation: string
  diagnosis: number[]
  additional_services: number[]
  hcp_service_areas: number[]
  preferencesCheckbox: boolean
  gender_preference: string
  language_preference: string
  day_preference: string[]
  time_preference: string[]
  terms: boolean
  additional_preference: string
  plan_management: string
  participant_age: string
  behaviour_relevance: string
  service_frequency: string
  additional_needs: string
  participant_goals: string
  consent_to_send_ndis_plan: boolean
  ndis_plan_file: File
}

export interface AdditionalQuoteQuestionProps extends RouteComponentProps {
    serviceAreas: ServiceArea[] | null,
    diagnosis: Diagnosis[] | null
  }
  

const NDISAdditionalQuestionsForm = (props: QuoteAdditionalFormProps) => {
  const {
    register,
    formState,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    setError,
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      participant_first_name: "",
      participant_last_name: "",
      participant_relation: "",
      diagnosis: [],
      additional_services: [],
      preferencesCheckbox: false,
      gender_preference: "",
      language_preference: "",
      day_preference: [],
      time_preference: [],
      terms: false,
      additional_preference: "",
      plan_management: "",
      participant_age: "",
      behaviour_relevance: "",
      service_frequency: "",
      additional_needs: "",
      participant_goals: "",
      consent_to_send_ndis_plan: false,
      hcp_service_areas: [],
    }
  });

  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const handleFileChange = (event) => {
    setIsFileUploaded(event.target.files.length > 0);
  };
  

  const quoteId = props && props.quoteId;
  
  const quoteDetailResult = useGetQuoteDetailFormQuery({ quoteId: quoteId! }); 
  
  let participantService = quoteDetailResult.data && quoteDetailResult.data.typeform_hidden_data.service_area;

  if (!participantService){
    participantService = props.selectedService && props.selectedService.name
  }
  const {errors} = formState;

  const submitForm = async (data: FormValues) => {
    try {
      await props.onSubmit({...data});
      reset({}, {keepValues: true});
    }
    catch (error) {
      console.error('Unexpected occurred:', error);
      setError('root', {message: 'Unexpected error'});
    }
  }

  const handlePreferencesCheckboxChange = () => {
    const preferencesCheckboxValue = watch('preferencesCheckbox');
  };

  const [showOtherGenderInput, setShowOtherGenderInput] = useState(false);
  const [selectedPlanManaged, setSelectedPlanManaged] = useState("");

  const handleGenderPreferenceChange = (selectedValue: string) => {
    if (selectedValue === 'Other') {
      setShowOtherGenderInput(true);
      setValue('gender_preference', '');
    } else {
      setShowOtherGenderInput(false);
    }
  };

  const handlePlanManagedChange = (selectedValue: string) => {
    setValue('plan_management', selectedValue);
    setSelectedPlanManaged(selectedValue);
  };
  // State to hold the value of participant's first name
  const [participantFirstName, setParticipantFirstName] = useState<string>('');

  // Handle change event for participant first name input
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParticipantFirstName(event.target.value);
  };

  const additionalServiceAreas = props.serviceAreas 
    ? props.serviceAreas.filter(serviceArea => 
        serviceArea !== props.selectedService
      )
    : [];

  return (
    <CenteredWithGutters>
      <form onSubmit={handleSubmit(submitForm)}>
      <StyledLabel>Name of participant</StyledLabel>
      <RowContainer>
        <FormItem style={{ marginRight: '24px' }}>
          <SubLabel>First Name*</SubLabel>
          <Input
            input={{placeholder:"", type: "text", ...register("participant_first_name", {required: true})}}
            meta={{submitFailed: !!errors.participant_first_name, error: "Participant first name is required"}}
            onChange={handleInputChange}
          />
        </FormItem>
        <FormItem style={{ marginRight: '24px' }}>
          <SubLabel>Last Name</SubLabel>
          <Input
            input={{placeholder:"", type: "text", ...register("participant_last_name", {required: false})}}
          />
        </FormItem>
      </RowContainer>
      <FormItem>
          <StyledLabel>{participantFirstName
            ? 
            <>
              What age is <strong>{participantFirstName}</strong>* ?
            </>
            : 'What age is the participant?*'}
          </StyledLabel>
          <Input
            input={{ placeholder: "Type here", type: "text", ...register("participant_age", { required: false }) }}
            meta={{ submitFailed: !!errors.language_preference, error: "Please enter age of the participant" }}
          />
        </FormItem>
        <FormItem>
              <div>
                <StyledLabel>{participantFirstName
                  ? <>
                    What is your relationship to <strong>{participantFirstName}</strong>*?
                  </>
                  : 'What is your relationship to the participant?*'}
                </StyledLabel>
                <Select
                    fieldOptions={concat(
                      { label: "This request is for myself", value: 'This request is for myself' },
                      { label: "I am their parent", value: 'I am their parent' },
                      { label: "I am their child", value: 'I am their child' },
                      { label: "I am their spouse/partner", value: 'I am their spouse/partner' },
                      { label: "I am their unpaid carer", value: 'I am their unpaid carer' },
                      { label: "I am their paid carer or case worker", value: 'I am their paid carer or case worker' },
                      { label: "Other", value: 'Other' },
                    )}
                    input={{placeholder: "Select from list", type: "text", ...register("participant_relation", {required: true})}}
                    meta={{submitFailed: !!errors.participant_relation, error: "Please select your relationship to the participant"}}
                />
              </div>
          </FormItem>
        <FormItem>
            {props.diagnosis && props.diagnosis.length > 0 && (
              <div>
                <StyledLabel>{participantFirstName
                  ? 
                  <>
                    Please tell us any relevant medical diagnosis or disability that relates to <strong>{participantFirstName}</strong>'s need for {participantService}? 
                  </>
                  : `Please tell us any relevant medical diagnosis or disability that relates to participant's need for ${participantService}`}
                </StyledLabel>
                <MultiSelect
                    fieldOptions={props.diagnosis.map(diag => ({
                      label: diag.name,
                      value: diag.name
                    }))}
                    input={{placeholder: "Select from list", type: "array", ...register("diagnosis", {required: false})}}
                    setValue={setValue} 
                />
              </div>
            )}
          </FormItem>
         <FormItem>
            <StyledLabel>{participantFirstName
                ? <>
                  What goals for <strong>{participantFirstName}</strong>, you want to achieve by engaging {participantService}?
                </>
                : `What goals for the participant, you want to achieve by engaging ${participantService}?`}
            </StyledLabel>
            <Input
              input={{ placeholder: "Type you answer here", type: "text", ...register("participant_goals", { required: false }) }}
            />
          </FormItem>
         <FormItem>
            <StyledLabel>{participantFirstName
                ? <>
                  Does <strong>{participantFirstName}</strong> have any behaviours relevant to this request that may impact on their care?
                </>
                : "Does the participant have any behaviours relevant to this request that may impact on their care?"}
            </StyledLabel>
            <Input
              input={{ placeholder: "Please briefly explain", type: "text", ...register("behaviour_relevance", { required: false }) }}
            />
          </FormItem>
        <FormItem>
            {additionalServiceAreas && additionalServiceAreas.length > 0 && (
              <div>
                <StyledLabel>{participantFirstName
                  ? 
                  <>
                    Does <strong>{participantFirstName}</strong> require any other services?
                  </>
                  : 'Does the participant require any other services?'}
                </StyledLabel>
                <MultiSelect
                    fieldOptions={additionalServiceAreas.map(serviceArea => ({
                      label: serviceArea.name,
                      value: serviceArea.name
                    }))}
                    input={{placeholder: "Select from list", type: "array", ...register("additional_services", {required: false})}}
                    setValue={setValue}
                />
              </div>
            )}
          </FormItem>
          <FormItem>
            <StyledLabel>{participantFirstName
                ? <>
                  Is there anything else specific to <strong>{participantFirstName}</strong>'s needs that you think is important that has not been covered in these questions?
                </>
                : "Is there anything else specific to participant's needs that you think is important that has not been covered in these questions?"}
            </StyledLabel>
            <Input
              input={{ placeholder: "Type you answer here", type: "text", ...register("additional_needs", { required: false }) }}
            />
          </FormItem>
            <FormItem>
              <div>
                <StyledLabel>{participantFirstName
                  ? <>
                    How is <strong>{participantFirstName}</strong>'s NDIS plan managed?
                  </>
                  : "How is participant's NDIS plan managed?"}
                </StyledLabel>
                <Select
                    fieldOptions={concat(
                      { label: "Self-managed NDIS plan", value: 'Self Managed' },
                      { label: "Managed by a registered plan management provider (not the NDIA)", value: 'Plan Managed' },
                      { label: "Agency-managed (by the NDIA)", value: 'Agency Managed' },
                      { label: "I'm not sure", value: "I'm not sure" },
                    )}
                    input={{
                      placeholder: "Select from list",
                      type: "text",
                      ...register("plan_management"),
                      onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                        setValue("plan_management", e.target.value);
                        handlePlanManagedChange(e.target.value);
                      },
                    }}
                />
              </div>
          </FormItem>
        <FormItem>
          <StyledLabel>
            {participantFirstName ? (
              <>
                Please upload <strong>{participantFirstName}</strong>'s NDIS Plan
              </>
            ) : (
              "Please upload participant's NDIS Plan"
            )}
          </StyledLabel>
          <input
              type="file"
              accept=".pdf,.jpg,.png,.jpeg"
              multiple
              {...register("ndis_plan_file", { required: false })}
              style={{ display: "block", border: "none", padding: "5px" }}
              onChange={handleFileChange}
            />
        </FormItem>
        {isFileUploaded && (
          <FormItem>
            <StyledLabel>Do you authorise Karista to forward your plan to the Provider we refer you to?*</StyledLabel> <br />
            <RadioButtonContainer>
              <Input
                input={{
                  type:"radio",
                  id:"consentYes",
                  value:true, 
                  ...register("consent_to_send_ndis_plan", { required: "Please select Yes or No" })}}
                meta={{
                    error: errors.consent_to_send_ndis_plan && errors.consent_to_send_ndis_plan.message
                }}
              />
              <StyledLabel htmlFor="consentYes">Yes</StyledLabel>

              <Input
                input={{
                  type:"radio",
                  id:"consentNo",
                  value:false, 
                  ...register("consent_to_send_ndis_plan", { required: "Please select Yes or No" })}}
                meta={{
                    error: errors.consent_to_send_ndis_plan && errors.consent_to_send_ndis_plan.message
                }}
              />
              <StyledLabel htmlFor="consentNo">No</StyledLabel>
            </RadioButtonContainer>
            {errors.consent_to_send_ndis_plan && (
              <ValidationError
                meta={{
                  submitFailed: !!errors.consent_to_send_ndis_plan,
                  error: "Please provide your consent"
                }} />
              
            )}
          </FormItem>
        )}
        <StyledLabel><b>Optional Preferences:</b></StyledLabel>
        <FormItem>
          <CheckboxForm
            input={{ type: "checkbox", ...register("preferencesCheckbox") }}
            meta={{ submitFailed: !!errors.preferencesCheckbox, error: "Please check the box if recipient has preferences" }}
            label={participantFirstName
              ? `Does ${participantFirstName} have preferences regarding gender, language, time, or other?`
              : 'Does the participant have preferences regarding gender, language, time, or other?'}
            onChange={handlePreferencesCheckboxChange}
          />
        </FormItem>
        {/* Conditionally render additional fields based on the checkbox value */}
        {watch("preferencesCheckbox") && (
          <>
            <FormItem>
              <StyledLabel>{participantFirstName
                ? 
                <>
                  Does <strong>{participantFirstName}</strong> have any gender preferences?
                </>
                : 'Does the participant have any gender preferences?'}
              </StyledLabel>
              <Select
                fieldOptions={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                  { label: "Other", value: "Other" },
                  { label: "No Preference", value: "No preference" },
                ]}
                input={{
                  placeholder: "Select from list",
                  type: "text",
                  ...register("gender_preference", { required: false }),
                  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                    setValue("gender_preference", e.target.value);
                    handleGenderPreferenceChange(e.target.value);
                  },
                }}
                meta={{ submitFailed: !!errors.gender_preference, error: "Please select a gender" }}
              />
            </FormItem>
            {showOtherGenderInput && (
            <FormItem>
              <Input
                input={{
                  placeholder: "Please type your gender preference",
                  type: "text",
                  ...register("gender_preference", { required: false }),
                }}
                meta={{ submitFailed: !!errors.gender_preference, error: "Please specify other gender preference" }}
              />
            </FormItem>
            )}
            <FormItem>
              <StyledLabel>{participantFirstName
                ? 
                <>
                  Does <strong>{participantFirstName}</strong> have any specific cultural or language needs?
                </>
                : 'Does the participant have any specific cultural or language needs?'}
              </StyledLabel>
              <Input
                input={{ placeholder: "Type here", type: "text", ...register("language_preference", { required: false }) }}
                meta={{ submitFailed: !!errors.language_preference, error: "Please enter a language" }}
              />
            </FormItem>
            <FormItem>
            <StyledLabel>{participantFirstName
                ? 
                <>
                  How regulary does <strong>{participantFirstName}</strong> require the service?
                </>
                : 'How regularly does the participant require the service?'}
              </StyledLabel>
              <Select
                fieldOptions={[
                  { label: "Daily", value: "Daily" },
                  { label: "Twice a week", value: "Twice a week" },
                  { label: "Weekly", value: "Weekly" },
                  { label: "Every fortnight", value: "Every fortnight" },
                  { label: "Monthly", value: "Monthly" },
                  { label: "Other", value: "Other" },
                ]}
                input={{
                  placeholder: "Select from list",
                  type: "text",
                  ...register("service_frequency", { required: false }),
                }}
              />
            </FormItem>
            <FormItem>
              <StyledLabel>{participantFirstName
              ? 
              <>
                Days <strong>{participantFirstName}</strong> requires services
              </>
              : 'Days participant requires services'}</StyledLabel>
              <CheckboxGroup
                fieldOptions={[
                  { label: "Monday", value: "Monday" },
                  { label: "Tuesday", value: "Tuesday" },
                  { label: "Wednesday", value: "Wednesday" },
                  { label: "Thursday", value: "Thursday" },
                  { label: "Friday", value: "Friday" },
                  { label: "Saturday", value: "Saturday" },
                  { label: "Sunday", value: "Sunday" },
                  { label: "No Preference", value: "No preference" },
                ]}
                control={control}
                name="day_preference"
                meta={{ submitFailed: !!errors.day_preference, error: "Please select at least one day" }}
              />
            </FormItem>
            <FormItem>
              <StyledLabel>Time of the Day</StyledLabel>
              <CheckboxGroup
                fieldOptions={[
                  { label: "Morning", value: "Morning" },
                  { label: "Afternoon", value: "Afternoon" },
                  { label: "Evening", value: "Evening" },
                  { label: "No Preference", value: "No preference" },
                ]}
                control={control}
                name="time_preference"
                meta={{ submitFailed: !!errors.time_preference, error: "Please select at least one time" }}
              />
            </FormItem>
            <FormItem>
              <StyledLabel>Additional Preferences</StyledLabel>
              <Input
                input={{ placeholder: "Please type here any additional preferences", type: "text", ...register("additional_preference", { required: false }) }}
              />
            </FormItem>
          </>
        )}
        <SubmitSection>
          <PrivacyLabel>
              We value your privacy and are committed to keeping your information confidential. 
              The details you provide will not be shared with anyone outside of our organisation. 
              We will only share your information with a trusted provider if you give us explicit consent to do so. 
          </PrivacyLabel>
          <SubmitLabel><b>Securely send your details by clicking on Submit</b></SubmitLabel>
          <BtnContainer>
            <Button disabled={formState.isSubmitting || formState.isSubmitSuccessful} fullWidth type="submit">
              Submit
            </Button>
          </BtnContainer>
        </SubmitSection>
        {errors.root && (
          <CenteredWithGutters>
              <ValidationError meta={{submitFailed: true, error: "Sorry, there has been a system error."}} >
                &nbsp;If this error persists, please <WhiteAnchor href="mailto:info@karista.com.au?Subject=I can't submit my request">contact us</WhiteAnchor>.
              </ValidationError>
          </CenteredWithGutters>
        )}
      </form>
    </CenteredWithGutters>
  );
}

export default NDISAdditionalQuestionsForm;
