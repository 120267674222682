import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import Pagination from '../../../components/Pagination';
import ProviderReview from '../../../components/ProviderReview';
import { GammaHeading, DeltaHeading } from '../../../components/Typography';

import ReviewsTitleContainer from '../../../components/ReviewsTitleContainer';
import calculateRem from '../../../styles/calculateRem';
import { createReviewsPaginationSubheading } from '../../../util/pagination';
import {Query} from '../../../state';
import { ProvidersActions } from '../actions';
import { useGetProviderReviewsQuery } from '../../../services/karista';
import {get} from "lodash";
import {stringify} from "qs";
import Button from '../../../components/Button';
import { colors } from '../../../styles/constants';
import { InternalLinks } from '../../../state';

export const NewWideLink = styled(Button)`
  margin-bottom: ${calculateRem(2)};
  margin-left: ${calculateRem(4)};
  width: auto;
`;

export const NewTransparentButton = styled(NewWideLink)`
  background-color: transparent;
  border-color: ${colors.darkPurple};
`;

const ReviewsFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${calculateRem(24)};
`;
const NoResults = () => <div>No reviews available yet</div>;

export interface ReviewsListProps extends RouteComponentProps<{id?: string, slug?: string}> {
  query?: Query,
  actions?: ProvidersActions,
  internalLinksData?: InternalLinks[]
};

const ProviderReviewsList: React.FC<ReviewsListProps> = (props) => {
  const { internalLinksData } = props;
  const getFormattedDescription = (
    desc: string,
    internalLinksData: { keyword: string; display_words: string; link: string }[] | undefined
  ) => {
    if (!internalLinksData || internalLinksData.length === 0) return desc;
  
    // Construct the replacements map dynamically
    const replacements: Record<string, string> = internalLinksData.reduce((acc, { keyword, display_words, link }) => {
      acc[keyword.toLowerCase()] = `<a href="${link}" rel="noopener noreferrer">${display_words}</a>`;
      return acc;
    }, {} as Record<string, string>);
  
    // Create a regex pattern from the keywords
    const pattern = new RegExp(`\\b(${Object.keys(replacements).join("|")})\\b`, "gi");
  
    return desc.replace(pattern, (match) => replacements[match.toLowerCase()]);
  };

  const providerId = parseInt(props.match.params.id as string);

  const page = Number(get(props.query, 'page', 1));

  const pageSize = 5;
  const {data: reviewsData, isLoading, isSuccess, isError} = useGetProviderReviewsQuery({providerId, page, pageSize});

  if (isLoading) {
    return <div />;
  }

  if (!isSuccess) {
    return <div />;
  }

  const subheading = createReviewsPaginationSubheading(page, pageSize, reviewsData && reviewsData.count);

  if (isError) {
    return <div />;
  }

  const handleClick = page => {
    props.history.push({
      pathname: props.match.url,
      search: stringify({
        ...props.query,
        page: page,
      })
    });
  }
  const formattedResults = reviewsData.results.map((review) => ({
    ...review,
    comment: getFormattedDescription(review.comment, internalLinksData),
    response: review.response
    ? { ...review.response, comment: getFormattedDescription(review.response.comment, internalLinksData) }
    : null
  }));
  
  return (
    <>
      <ReviewsFlexWrapper id="provider-reviews">
        <ReviewsTitleContainer>
          <GammaHeading>Reviews</GammaHeading>
          <DeltaHeading>{subheading}</DeltaHeading>
        </ReviewsTitleContainer>
      </ReviewsFlexWrapper>
      
      {formattedResults.length > 0 ? (
        formattedResults.map((review, i) => (
          <ProviderReview
            key={`provider-${providerId}-review-${i}`} // eslint-disable-line
            {...review}
          />
        ))
      ) : (
        <NoResults />
      )}
      {reviewsData.totalPages > 1 && (
        <Pagination current={page} {...reviewsData} onClick={handleClick} />
      )}
    </>
  )
};

export default ProviderReviewsList;
