import React from 'react';
import DOMPurify from 'dompurify';
import { useWindowSize } from '../../../../util/useWindowSize';

import Theme from '../../../../components/Theme';
import { themeSecondary } from '../../../../styles/constants';
import {
  InformationSection,
  ParagraphHeaderText,
  InfoContentText,
} from "../../style";


interface HeroProps {
  info_sections: { id: number; content_question: string; content_answer: string, mobile_version_content_answer: string, same_mobile_version: boolean }[];
};

const ServiceInfo: React.FC<HeroProps> = ({ info_sections }) => {
  const { width, height } = useWindowSize();

  // Sort the info_sections by id to ensure they are rendered in the correct order
  const sortedInfoSections = [...info_sections].sort((a, b) => a.id - b.id);

  return (
    <Theme theme={themeSecondary}>
      <InformationSection>
        {sortedInfoSections.map((section, index) => (
          <div key={section.id}>
            <ParagraphHeaderText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.content_question) }} />
            {width <= 720 && !section.same_mobile_version && section.mobile_version_content_answer
              ? <InfoContentText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.mobile_version_content_answer, { ADD_ATTR: ["target"] }) }} />
              : <InfoContentText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.content_answer, { ADD_ATTR: ["target"] }) }} />
            }
            <br />
          </div>
        ))}
      </InformationSection>
    </Theme>
  );
};

export default ServiceInfo;
