/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable consistent-return */

import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";

import Input from '../../../../components/InputComplaints';
import Button from '../../../../components/Button';
import Textarea from '../../../../components/Textarea';
import Checkbox from '../../../../components/Checkbox';
import ValidationError from '../../../../components/ValidationError';
import FormItem from '../../../../components/FormItem';
import Select from '../../../../components/Select';
import styles from "../../../../styles/global.module.css"
import { isEmail } from 'validator';
import {isValidPhoneNumber} from "libphonenumber-js";
import { concat, isEmpty } from 'lodash';

import {
    SubLabel,
    SubmitSection,
    PrivacyLabel
} from '../../../Static/components/ComplaintsAndFeddbackQuestions/style';

import {
  CenteredWithGutters,
  BtnContainer,
  RowContainer
} from '../../../../components/HCPAdditionalQuestionsForm/styles';

import {WhiteAnchor} from "../../../../components/Link";
import { AgeGroup, FundingType, Postcode, ServiceArea } from '../../../../state';

// These types are for ease of managing form state, final
// data types for submission are below.
type FormValues = {
  first_name: string
  last_name: string
  email: string
  number: string
  postcode: string
  service_required: ServiceArea[] | null
  funding_type: {[k: string]: FundingType},
  age_group: {[k: string]: AgeGroup}
  details: string
  terms_and_conditions: boolean
}

// This is different from the form state values.
// The transformation happens on delegation to the submit handler.
export type NoProviderFoundContactRequestSubmitData = {
    first_name: string
    last_name: string
    email: string
    number: string
    postcode: string
    service_required: string
    funding_type: string
    age_group: string
    details: string
    terms_and_conditions: boolean
} 

const NoProviderFoundForm = (props) => {
    const {
        register,
        formState,
        handleSubmit,
        setValue,
        watch,
        reset,
        control,
        setError,
    } = useForm<FormValues>({
        mode: "all",
        defaultValues: {
            first_name: "",
            last_name: "",
            email: "",
            number: "",
            postcode: "",
            service_required: [],
            funding_type: {},
            age_group: {},
            details: "",
            terms_and_conditions: false
        }
    });
    const {errors} = formState;
    const {
        filters,
        ageGroups,
        fundingTypes,
        serviceAreas,
    } = props;

    const serviceName = filters && filters.service_areas_list;

    const ageDescription = (
        ageGroups
        ? ageGroups[filters.age_groups_list].code
        : null
    );
    const fundingName = (
        (fundingTypes && filters.funding_types_list && fundingTypes[filters.funding_types_list])
        ? fundingTypes[filters.funding_types_list].code
        : null
    );
    const postcode = filters && filters.postcodes_list

    useEffect(() => {
        if (ageDescription) {
            setValue("age_group", ageDescription);
        }
        if (serviceName) {
        setValue("service_required", serviceName);
        }
        if (ageDescription) {
        setValue("age_group", ageDescription);
        }
        if (fundingName) {
        setValue("funding_type", fundingName);
        }
        if (postcode) {
        setValue("postcode", postcode);
        }
    }, [serviceName, ageDescription, fundingName, postcode, setValue]);

    const submitForm = async (data: FormValues) => {
        try {
        await props.onSubmit({...data});
        reset({}, {keepValues: true});
        }
        catch (error) {
        console.error('Unexpected occurred:', error);
        setError('root', {message: 'Unexpected error'});
        }
    }

    return (
        <CenteredWithGutters>
        <form onSubmit={handleSubmit(submitForm)}>
        <RowContainer style={{ marginTop: '24px' }}>
            {window.innerWidth > 768 ? (
                <FormItem style={{ marginRight: '24px' }}>
                <SubLabel>Your first name*</SubLabel>
                <Input
                    input={{type: "text", ...register("first_name", {required: true})}}
                    meta={{submitFailed: !!errors.first_name, error: "First name is required"}}
                />
                </FormItem>
            ) :(
            <FormItem>
                <SubLabel>Your first name*</SubLabel>
                <Input
                input={{type: "text", ...register("first_name", {required: true})}}
                meta={{submitFailed: !!errors.first_name, error: "First name is required"}}
                />
            </FormItem>
            )}
            <FormItem>
            <SubLabel>Your surname</SubLabel>
            <Input
                input={{type: "text", ...register("last_name")}}
                meta={{submitFailed: !!errors.last_name, error: "Surname is required"}}
            />
            </FormItem>
            </RowContainer>
            <FormItem>
            <SubLabel>Email*</SubLabel>
            <Input
                input={{type: "text", ...register("email", {required: true, validate: (val) => isEmail(val)? undefined : "Invalid URL"})}}
                meta={{submitFailed: !!errors.email, error: "Please enter a valid email"}}
            />
            </FormItem>
            <RowContainer>
                {window.innerWidth > 768 ? (
                    <FormItem className={styles.halfWidthDesktop} style={{ marginRight: '24px' }}>
                        <SubLabel>Phone*</SubLabel>
                        <Input
                            input={{type: "text", ...register("number", {required: true, validate: (val) => isValidPhoneNumber(val, "AU")})}}
                            meta={{submitFailed: !!errors.number, error: "Please enter a valid phone number"}}
                        />
                    </FormItem>
                ) : ( 
                    <FormItem>
                        <SubLabel>Phone*</SubLabel>
                        <Input
                            input={{type: "text", ...register("number", {required: true, validate: (val) => isValidPhoneNumber(val, "AU")})}}
                            meta={{submitFailed: !!errors.number, error: "Please enter a valid phone number"}}
                        />
                    </FormItem>
                )}
                <FormItem className={styles.halfWidthDesktop}>
                    <SubLabel>Postcode or suburb</SubLabel>
                    <Input
                        input={{type: "text", ...register("postcode", {required: true})}}
                    />
                </FormItem>
            </RowContainer>
            <FormItem>
                <SubLabel emphasis htmlFor="ageGroup">
                    Service required
                </SubLabel>
                {(!!serviceAreas && !isEmpty(serviceAreas)) ? (
                    <Select
                    input={{placeholder: "Select from list", type: "text", ...register("service_required", {required: true})}}
                    meta={{submitFailed: !!errors.service_required, error: "Please select a service you are searching"}}
                    fieldOptions={Object.keys(serviceAreas).map(key => ({
                        label: serviceAreas[key].name,
                        value: serviceAreas[key].code
                    }))}
                    />
                ) : (
                    <div>Loading ...</div>
                )}
            </FormItem>
            <RowContainer>
                {window.innerWidth > 768 ? (
                    <FormItem className={styles.halfWidthDesktop} style={{ marginRight: '24px' }}>
                        <SubLabel emphasis htmlFor="ageGroup">
                            Age of recipient
                        </SubLabel>
                        {(!!ageGroups && !isEmpty(ageGroups)) ? (
                            <Select
                            input={{placeholder: "Select from list", type: "text", ...register("age_group", {required: true})}}
                            meta={{submitFailed: !!errors.age_group, error: "Please select the age group"}}
                            fieldOptions={Object.keys(ageGroups).map(key => ({
                                label: ageGroups[key].description,
                                value: ageGroups[key].code
                            }))}
                            />
                        ) : (
                            <div>Loading ...</div>
                        )}
                    </FormItem>
                ) :(
                    <FormItem className={styles.halfWidthDesktop}>
                        <SubLabel emphasis htmlFor="ageGroup">
                            Age of recipient
                        </SubLabel>
                        {(!!ageGroups && !isEmpty(ageGroups)) ? (
                            <Select
                            input={{placeholder: "Select from list", type: "text", ...register("age_group", {required: true})}}
                            meta={{submitFailed: !!errors.age_group, error: "Please select your relationship to the participant"}}
                            fieldOptions={Object.keys(ageGroups).map(key => ({
                                label: ageGroups[key].description,
                                value: ageGroups[key].code
                            }))}
                            />
                        ) : (
                            <div>Loading ...</div>
                        )}
                    </FormItem>
                )}
                <FormItem className={styles.halfWidthDesktop}>
                    <SubLabel emphasis htmlFor="ageGroup">
                        Funding type
                    </SubLabel>
                    {(!!fundingTypes && !isEmpty(fundingTypes)) ? (
                        <Select
                        input={{placeholder: "Select from list", type: "text", ...register("funding_type", {required: true})}}
                        meta={{submitFailed: !!errors.funding_type, error: "Please select a funding type"}}
                        fieldOptions={concat(Object.keys(fundingTypes).map(key => ({
                            label: fundingTypes[key].name,
                            value: fundingTypes[key].code
                        })),
                        { label: "I don't know", value: 'i-don-t-know' })}
                        />
                    ) : (
                        <div>Loading ...</div>
                    )}
                </FormItem>
            </RowContainer>
            <FormItem>
            <SubLabel>Is there any other information that might help us to find you services</SubLabel>
            <Textarea className={styles.fullWidth} style={{background: 'white'}}
                input={{type: "text", ...register("details", {required: false})}}
            />
            </FormItem>
            <FormItem style={{display: 'flex', flexDirection: 'row'}}>
                
                <Checkbox
                    input={{ type: "checkbox", ...register("terms_and_conditions", {required: true}) }}
                    meta={{submitFailed: !!errors.terms_and_conditions, error: 'Please agree to the terms and conditions before proceeding'}}
                />
                <SubLabel>I agree to the Karista{' '}
                    <a href="/terms-and-conditions" target="_blank">
                    Terms and Conditions
                    </a>{' '}
                    and{' '}
                    <a href="/privacy-policy" target="_blank">
                    Privacy Policy
                    </a>
                </SubLabel>
            </FormItem>
            <SubmitSection>
                <PrivacyLabel>
                    We value your privacy and are committed to keeping your information confidential. 
                    The details you provide will not be shared with anyone outside of our organisation. 
                    We will only share your information with a trusted provider if you give us explicit consent to do so.
                </PrivacyLabel>
                <BtnContainer>
                    <Button disabled={formState.isSubmitting || formState.isSubmitSuccessful} fullWidth type="submit">
                        Submit
                    </Button>
                </BtnContainer>
            </SubmitSection>
            {errors.root && (
            <CenteredWithGutters>
                <ValidationError meta={{submitFailed: true, error: "Sorry, there has been a system error."}} >
                    &nbsp;If this error persists, please <WhiteAnchor href="mailto:info@karista.com.au?Subject=I can't submit my request">contact us</WhiteAnchor>.
                </ValidationError>
            </CenteredWithGutters>
            )}
        </form>
        </CenteredWithGutters>
    );
}

export default NoProviderFoundForm;
