/* eslint-disable */
import React, { useState } from 'react';
import { find } from 'lodash';
import Helmet from 'react-helmet';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import RequestAQuoteForm from '../../../components/RequestAQuoteForm';
import { RouteComponentProps } from 'react-router';
import { RequestQuoteResult, ServiceArea } from '../../../state/types';
import { trackQuoteSubmission } from "../tracking";
import { useAppSelector } from '../../../state/hooks';
import { useCreateRequestForQuoteFormMutation } from '../../../services/karista';
import { OrangeLink } from '../../../components/Link';
import { useGetAllAgeGroupsQuery, useGetAllFundingTypesQuery } from '../../../services/karista';
import { reduceQueries } from '../../../util/rtk-query';
import FormProgressBar from '../../../components/FormProgressBar';
import Theme from '../../../components/Theme';
import { themeLightGrey } from '../../../styles/constants';
import { postcodeExactDetailText } from '../../../util/transforms';
import karistaApi from "../../../services/karista";
import { HeroSection, FormSection, HeaderTextPurple, LeftAlignedContainer, BetaHeaderHeading, LeftAlignedHeader } from './styles';

const Error = () => <div>An error occured, please refresh your browser</div>;

export interface RequestQuoteMultipleProps extends RouteComponentProps {
  requestQuoteResult: RequestQuoteResult,
  serviceAreas: ServiceArea[] | null,
}

const RequestQuoteMultiple: React.FC<RequestQuoteMultipleProps> = props => {
  const {
    requestQuoteResult,
    serviceAreas,
    ...restProps
  } = props;
  let steps = ['About You', 'Participant Details', 'Confirmation'];
  const [currentStep, setCurrentStep] = useState(0);

  const useInitialize = () => reduceQueries({
    ageGroups: useGetAllAgeGroupsQuery(),
    fundingTypes: useGetAllFundingTypesQuery()
  });

  const { ageGroups, fundingTypes, isLoading } = useInitialize();
  const ageGroupsData = ageGroups.data;
  const fundingTypesData = fundingTypes.data

  const { filters, providersToQuote } = useAppSelector(state => state.providers.providerSearchParams);
  const [createRequestForQuote] = useCreateRequestForQuoteFormMutation();
  if (filters.funding_types_list && ["HCP", "HCP1", "HCP2", "HCP3", "HCP4"].includes(filters.funding_types_list)){
    steps = ['About You', 'Recipient Details', 'Confirmation'];
  }
  if (!serviceAreas || isLoading) {
    return <div>Loading ...</div>;
  }

  const selectedServiceArea = find(
    serviceAreas,
    serviceArea =>
      serviceArea.name == filters['service_areas_list']
  );

  const selectedAgeGroup = filters.age_groups_list && ageGroupsData && ageGroupsData[filters.age_groups_list];
  const selectedFundingType = filters.funding_types_list && fundingTypesData && fundingTypesData[filters.funding_types_list];

  function onSubmit(formData) {
    const submitData = Object.assign(                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
      {},
      {
        providers: providersToQuote,
        service_area: (selectedServiceArea as ServiceArea).id
      },
      { ...formData, ...filters },
    );
    return createRequestForQuote(submitData)
      .unwrap()
      .then(async (res) => {
        const service = filters.service_areas_list;
        await trackQuoteSubmission(service);
        return res;
      }).then((res) => {
        if (filters.funding_types_list && ["HCP", "HCP1", "HCP2", "HCP3", "HCP4"].includes(filters.funding_types_list)) {
          props.history.push(`/providers/quote-detail/additional-questions-hcp/${res.id}`);
        }
        else {
          props.history.push(`/providers/quote-detail/additional-questions-ndis/${res.id}`);
        }
      });
  }

  if (providersToQuote.length === 0) {
    return <Error />;
  }

  const { data: postcodesDetail } = karistaApi.useGetPostcodeQuery(
    !!filters.postcodes_list ? filters.postcodes_list : skipToken,
  );

  return (
    <Theme theme={themeLightGrey}>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {!!providersToQuote && (
        <>
        <FormProgressBar steps={steps} currentStep={currentStep} />
        <HeroSection>
          <div>
            <LeftAlignedHeader>
              <BetaHeaderHeading>About You</BetaHeaderHeading>
            </LeftAlignedHeader>
            <FormSection>
              <HeaderTextPurple><b>Let us help you connect to providers</b></HeaderTextPurple>
              <p>
                Please answer these questions to help us connect you.
              </p>
                <RequestAQuoteForm
                  selectedServiceArea={selectedServiceArea && selectedServiceArea.id}
                  onSubmit={onSubmit}
                  serviceAreaCheck={filters.service_areas_list}
                  postcode={filters.postcodes_list}
                  serviceAreasList={serviceAreas}
                  suburb={filters.suburb_list}
                />
              </FormSection>
            </div>
            <LeftAlignedContainer>
              {filters.service_areas_list && filters.postcodes_list && filters.age_groups_list && filters.funding_types_list ? 
                (<div>
                    <p>
                      You have selected <b>{providersToQuote.length}</b>
                      {providersToQuote.length > 1 ? ' providers ' : ' provider '}
                    </p>
                    <p>for <b>{filters.service_areas_list}</b></p>
                    {(filters.suburb_list && filters.postcodes_list) ? (
                      <p>in <b>{postcodeExactDetailText(filters.suburb_list, filters.postcodes_list, postcodesDetail)}</b></p>
                    ) : filters.postcodes_list ? (
                      <p>in <b>{filters.postcodes_list}</b></p>
                    ) : null}
                    {selectedAgeGroup && selectedAgeGroup.description && (
                      <p>for <b>{selectedAgeGroup.description}</b></p>
                    )}
                    {selectedFundingType && selectedFundingType.name && (
                      <p>under <b>{selectedFundingType.name}</b>.</p>
                    )}
                  {" "} 
                  <OrangeLink to="/">
                  Modify search
                  </OrangeLink> 
                  <br></br>
                </div>
                ) : (
                  <div>
                    You have selected <b>{providersToQuote.length}</b>
                    {providersToQuote.length > 1 ? ' providers.' : ' provider.'}
                  </div>
                )}
              </LeftAlignedContainer>
        </HeroSection>
        </>
      )}
    </Theme>
  );
}

export default RequestQuoteMultiple;
