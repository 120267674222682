import React, { useState } from 'react';

import { ButtonLink } from '../../../components/Link'; // eslint-disable-line
import Hero from '../../../components/Hero';
import { BetaHeading } from '../../../components/Typography';
import { QuoteSuccessPixel } from '../../../components/Trackers';
import { Query } from '../../../state/types';
import { MessageSection, ContentSection, ButtonWrapper, WideLink, HeaderSection, SucessHeaderTextPurple, HeaderTextPurple } from './styles';
import FormProgressBar from '../../../components/FormProgressBar';

interface QuoteSuccessProps {
  query: Query,
};

const steps = ['About You', 'Recipient Details', 'Confirmation'];

const QuoteSuccess: React.FC<QuoteSuccessProps> = props => {
  const { query } = props;

  const [currentStep, setCurrentStep] = useState(2);

  let search = '';
  if (!!query && query.query) {
    search += `&query=${query.query}`;
  }
  if (!!query && query.filters) {
    search += '&filters=';
    search += encodeURIComponent(query.filters as string);
  }


  return (
    <div>
      <FormProgressBar steps={steps} currentStep={currentStep} />
      <QuoteSuccessPixel />
      <Hero>
        <HeaderSection>
          <BetaHeading>You request has been received</BetaHeading>
        </HeaderSection>
      </Hero>
      <ContentSection>
        <p>
          <b>Our Client Services team will contact you</b> between 9am - 5pm AEST to discuss your needs.
          Please keep an eye out for a call from <a href="tel:+61485972676">0485 972 676</a> and/or an email from <a href="mailto:clientservicesteam@karista.com.au">clientservicesteam@karista.com.au</a>.
          <br/>
          <br/>
          <b>You should have received a confirmation email.</b><br/>
          If you don’t see it in your inbox, please check your junk or spam folder.
          <br/>
        </p>
        <ButtonWrapper>
          <ButtonLink
            to={{
              pathname: '/',
              search
            }}
          >
            <WideLink>Back to search</WideLink>
          </ButtonLink>
        </ButtonWrapper>
      </ContentSection>
    </div>
  );
}

export default QuoteSuccess;
