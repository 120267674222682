import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { reduceQueries } from '../../../util/rtk-query';

import Pagination from '../../../components/Pagination';
import ProviderReview from '../../../components/ProviderReview';
import StyledLink from '../../../components/Link';
import { BetaHeading, DeltaHeading } from '../../../components/Typography';

import ProfileHeader, { WideLink } from '../../../components/ProfileHeader';
import ReviewsTitleContainer from '../../../components/ReviewsTitleContainer';
import calculateRem from '../../../styles/calculateRem';
import { createReviewsPaginationSubheading } from '../../../util/pagination';
import {Query} from '../../../state';
import { ProvidersActions } from '../actions';
import { useGetProviderDetailsUsingSlugQuery, useGetProviderReviewsQuery } from '../../../services/karista';
import {get} from "lodash";
import {stringify} from "qs";
import Button from '../../../components/Button';
import { colors } from '../../../styles/constants';
import karistaApi from '../../../services/karista';
import {
  ButtonsContainer,
  TransparentButton
} from '../../../components/ProviderDetails/styles';

const useInitialize = () => reduceQueries({
  serviceAreas: karistaApi.useGetAllServiceAreasQuery(),
  internalLinks: karistaApi.useGetAllInternalLinksQuery()
});

export const NewWideLink = styled(Button)`
  margin-bottom: ${calculateRem(2)};
  margin-left: ${calculateRem(4)};
  width: auto;
`;

export const NewTransparentButton = styled(NewWideLink)`
  background-color: transparent;
  border-color: ${colors.darkPurple};
`;

const ReviewsFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${calculateRem(24)};
`;
const NoResults = () => <div>No reviews available yet</div>;

export interface ReviewsListProps extends RouteComponentProps<{id?: string, slug?: string}> {
  query: Query,
  actions: ProvidersActions,
};

const ReviewsList: React.FC<ReviewsListProps> = (props) => {
  const { serviceAreas, internalLinks, isLoading: internalLinksIsLoading } = useInitialize();
  const internalLinksData = internalLinks.data || [];
  const serviceAreaData = serviceAreas.data || [];

  const providerId = parseInt(props.match.params.id as string);
  const providerSlug = props.match.params.slug as string;

  const page = Number(get(props.query, 'page', 1));

  const pageSize = 5;
  const {data: providerData, isLoading: providerIsLoading, isSuccess: providerIsSuccess} = useGetProviderDetailsUsingSlugQuery(providerSlug);
  const {data: reviewsData, isLoading, isSuccess, isError} = useGetProviderReviewsQuery({providerId, page, pageSize});

  if (providerIsLoading || isLoading) {
    return <div />;
  }

  if (!providerIsSuccess || !isSuccess) {
    return <div />;
  }

  const subheading = createReviewsPaginationSubheading(page, pageSize, reviewsData && reviewsData.count);

  const getFormattedDescription = (
    desc: string,
    internalLinksData: { keyword: string; display_words: string; link: string }[] | undefined
  ) => {
    if (!internalLinksData || internalLinksData.length === 0) return desc;
  
    // Construct the replacements map dynamically
    const replacements: Record<string, string> = internalLinksData.reduce((acc, { keyword, display_words, link }) => {
      acc[keyword.toLowerCase()] = `<a href="${link}" rel="noopener noreferrer">${display_words}</a>`;
      return acc;
    }, {} as Record<string, string>);
  
    // Create a regex pattern from the keywords
    const pattern = new RegExp(`\\b(${Object.keys(replacements).join("|")})\\b`, "gi");
  
    return desc.replace(pattern, (match) => replacements[match.toLowerCase()]);
  };

  const handleClick = page => {
    const providerId = parseInt(props.match.params.id as string);
    const providerSlug = props.match.params.slug as string;
    const updatedPathname = `/providers/${providerSlug}/${providerId}/reviews`;
    props.history.push({
      pathname: updatedPathname,
      search: stringify({
        ...props.query,
        page: page,
      })
    });
  }

  const formattedResults = reviewsData.results.map((review) => ({
    ...review,
    comment: getFormattedDescription(review.comment, internalLinksData),
    response: review.response
    ? { ...review.response, comment: getFormattedDescription(review.response.comment, internalLinksData) }
    : null
  }));

  const serviceAreaLookup = Object.fromEntries(serviceAreaData.map(area => [area.id, area.name]));

  // Generate meta keywords from services and funding types
  const metaKeywords = [
    ...providerData.service_areas.map(serviceId => serviceAreaLookup[serviceId] || 'karista, ndis, hcp'),
    ...providerData.funding_types.map(fundingType => fundingType.toLowerCase())
  ].join(", ");

  return (
    <>
      <Helmet>
        <title>{`${providerData.name} - Customer Reviews on Karista`}</title>
        <meta name="description" content={`Read reviews of individuals how have experienced services of ${providerData.name}. Explore how ${providerData.name} has helped individuals achieve their goals.`} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
    <ProfileHeader
      logoUrl={providerData.logo_url}
      providerId={providerId}
      name={providerData.name}
      hasReviews
      purple
      isActive={providerData.is_active}
      providerSlug={providerData.url_slug}
      {...props}
    >
      <>
        <ButtonsContainer>
            <StyledLink to={`/providers/${providerSlug}/${providerId}/review/`}>
              <TransparentButton>Review this provider</TransparentButton>
            </StyledLink>
            <StyledLink to={`/providers/${providerSlug}/${providerId}/request-quote/`}>
              <WideLink>Connect with the Provider</WideLink>
            </StyledLink>
          </ButtonsContainer>
        <ReviewsFlexWrapper>
          <ReviewsTitleContainer>
            <BetaHeading>Reviews</BetaHeading>
            <DeltaHeading>{subheading}</DeltaHeading>
          </ReviewsTitleContainer>
        </ReviewsFlexWrapper>
      </>
      
      {formattedResults.length > 0 ? (
        formattedResults.map((review, i) => (
          <ProviderReview
            key={`provider-${providerId}-review-${i}`} // eslint-disable-line
            {...review}
          />
        ))
      ) : (
        <NoResults />
      )}
      {reviewsData.totalPages > 1 && (
        <Pagination current={page} {...reviewsData} onClick={handleClick} />
      )}
    </ProfileHeader>
    </>
  )
};

export default ReviewsList;
