import React from 'react';
import styled from 'styled-components';

import logoHorizontal from './logo_horizontal.png';
import logoSmall from './logo_small.png';
import logoRegistered from './logo_registered.svg';
import mediaQueries from '../../styles/mediaQueries';
import Breakpoint from '../Breakpoint';

export const Horizontal = styled.img`
  height: 30px;
`;

export const Small = styled.img`
  height: 30px;
`;

export const Vertical = styled.img`
  height: 108px;
  ${mediaQueries.mobile`
    display: none;
  `};
`;

export const HomePageVertical = styled.img`
  height: 140px;
  ${mediaQueries.mobile`
    display: none;
  `};
`;

export const LogoResponsive = () => (
  <Breakpoint>
    {(breakpoint) =>
      breakpoint === 'desktop' ? (
        <Horizontal alt="Karista logo" src={logoHorizontal} />
      ) : (
        <Small alt="Karista logo" src={logoSmall} />
      )
    }
  </Breakpoint>
);

export const LogoHorizontal = () => (
  <Horizontal alt="Karista logo" src={logoHorizontal} />
);

export const LogoVertical = () => (
  <Vertical alt="Karista logo" src={logoRegistered} />
);

export const HomePageLogoVertical = () => (
  <HomePageVertical alt="Karista logo" src={logoRegistered} />
);

export const LogoSmall = () => (
  <Horizontal alt="Karista logo" src={logoSmall} />
);
