import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/constants';

const cardStackBreakpoint = '720px';

const MainContainer = styled.div`
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 40px;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 85%;
  }
`

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: ${colors.lightPurple};
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: ${colors.purple};
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: width 0.4s ease-in-out;
    transform: translateY(-50%);
    left: 0;
  }
`

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`

const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color:${colors.white};
  border: 3px solid ${({ step }) =>
      step === 'completed' ? colors.white : colors.purple};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StepCount = styled.span`
  font-size: 19px;
  color: ${colors.purple};
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  width: 125px;
  transform: translate(-50%, -50%);
  text-align: center;
`

const StepLabel = styled.span`
  font-size: 14px;
  color:${colors.purple};
`

const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: ${colors.purple};
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`

const FormProgressBar = ({ steps, currentStep })=> {

  const totalSteps = steps.length
  const width = `${(currentStep / (totalSteps - 1)) * 100}%`;

  return (
    <MainContainer>
      <StepContainer width={width}>
        {steps.map((step, index) => (
          <StepWrapper key={index}>
            <StepStyle index={currentStep >= index ? 'completed' : 'incomplete'}>
              {currentStep > index ? (
                <CheckMark>L</CheckMark>
              ) : (
                <StepCount>{index+1}</StepCount>
              )}
            </StepStyle>
            <StepsLabelContainer>
              <StepLabel key={index}>{step}</StepLabel>
            </StepsLabelContainer>
          </StepWrapper>
        ))}
      </StepContainer>
    </MainContainer>
  )
}

export default FormProgressBar;