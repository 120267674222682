/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components';

import Section from '../../../components/Section';
import calculateRem from '../../../styles/calculateRem';
import { RouteComponentProps } from 'react-router';
import { Diagnosis, ServiceArea } from '../../../state/types';
import { useAppSelector } from '../../../state/hooks';
import { useUpdateRequestForQuoteFormMutation } from '../../../services/karista';
import NDISAdditionalQuestionsForm from '../../../components/NDISAdditionalQuestionsForm';
import { trackQuoteSubmission } from "../tracking";
import { FormSection, BetaHeaderHeading, LeftAlignedHeader } from './styles';
import Theme from '../../../components/Theme';
import { themeLightGrey } from '../../../styles/constants';
import FormProgressBar from '../../../components/FormProgressBar';

const StyledSection = styled(Section)`
  padding-top: ${calculateRem(48)};
`;

export interface NDISAdditionalQuoteQuestionProps extends RouteComponentProps<{ id?: string }> {
  serviceAreas: ServiceArea[] | null,
  diagnosis: Diagnosis[] | null
  
}

const steps = ['About You', 'Participant Details', 'Confirmation'];

const NDISAdditionalQuestions: React.FC<NDISAdditionalQuoteQuestionProps> = props => {
  const {
    serviceAreas,
    diagnosis,
    ...restProps
  } = props;

  const [currentStep, setCurrentStep] = useState(1);
  const quoteId = props.match.params.id;

  const { filters, providersToQuote } = useAppSelector(state => state.providers.providerSearchParams);
  const [updateRequestForQuote] = useUpdateRequestForQuoteFormMutation();

  if (!serviceAreas || !diagnosis) {
    return <div>Loading ...</div>;
  }

  function onSubmit(formData) {
    const submitData = Object.assign(                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
      {},
      {
        id: quoteId
      },
      { ...formData, ...filters },
    );
    return updateRequestForQuote(submitData)
      .unwrap()
      .then(async (res) => {
        const service = filters.service_areas_list;
        await trackQuoteSubmission(service);
        return res;
      })
      .then((res) => {
        props.history.push(`/providers/quote-success`);
      });
  }

  return (
    <Theme theme={themeLightGrey}>
        <FormProgressBar steps={steps} currentStep={currentStep} />
        <StyledSection>
          <LeftAlignedHeader>
            <BetaHeaderHeading>Participant Details & Service Preferences</BetaHeaderHeading>
          </LeftAlignedHeader>
          <FormSection>  
            <NDISAdditionalQuestionsForm
              onSubmit={onSubmit}
              serviceAreas={serviceAreas}
              diagnosis={diagnosis}
              selectedService={serviceAreas.find(serviceArea => serviceArea.name === filters.service_areas_list)}
              quoteId={quoteId}
            />
          </FormSection>
        </StyledSection>
    </Theme>
  );
}

export default NDISAdditionalQuestions;
