import React from "react";

import { MultiVideoPlayer } from "../../../../components/VideoPlayer";
import AdminProviderHeading from "../../../../components/AdminProviderHeading";
import { CenteredWithGutters } from "../../../../components/ProviderDetailsForm/styles";
import { StyledExternalLink } from "../../../../components/Link";
import { Provider, ProviderId } from "../../../../state/types";
import { PROVIDER_TABS_WELCOME } from "../../constants";
import {
  StyledAlphaHeading,
  StyledGammaHeading,
  Columns,
  CardHeading,
  WideCard,
  CardLead,
  VideoColumns,
  StyledContactText,
  StyledContactLink,
  ToolkitColumns,
} from "./styles";
import Downloadable from "./Downloadable";
import { downloads } from "./downloads";

interface WelcomeProviderProps {
  authorizedProviderIds: ProviderId[];
  provider?: Provider;
}

const WelcomeProvider: React.FC<WelcomeProviderProps> = ({
  authorizedProviderIds: providerIds,
  provider,
}) => {
  const numDownloads = downloads.length;
  const maxDownloadsPerLine = 2;
  const minWidthPerItem = "40%"; // allow for margin
  const offsetAmount =
    numDownloads > maxDownloadsPerLine ? numDownloads % maxDownloadsPerLine : 0;
  const offsetWidthPerItem = `${(9 / offsetAmount) * 10}%`;
  const downloadItems = downloads.map(({ title, url, preview }, index) => (
    <div
      key={`download_${url}`}
      style={{
        flex: 1,
        alignSelf: "stretch",
        flexBasis: index < offsetAmount ? offsetWidthPerItem : minWidthPerItem,
      }}
    >
      <Downloadable key={title} title={title} url={url} preview={preview} />
    </div>
  ));

  return (
    <div>
      {providerIds && (
        <AdminProviderHeading
          currentPage={PROVIDER_TABS_WELCOME}
          providerId={provider && provider.id}
          providerName={provider && provider.name}
          providerSlug={provider && provider.url_slug}
          providerRating={provider && provider.overall_review}
        />
      )}
      <CenteredWithGutters>
        <section>
          <StyledAlphaHeading>Welcome</StyledAlphaHeading>
          <p>
            Welcome to your Provider Admin portal. Here you will find your
            Profile information page and access to your Reviews.
          </p>
          <p>There are also helpful videos and a Provider toolkit.</p>
        </section>
        <StyledGammaHeading>Your subscription</StyledGammaHeading>
        <p>
          Your subscription provides you with a listing on the Karista website
          and the ability to collect requests and referrals, as well as reviews.
          If you want to unsubscribe, we require 30 days’ notice in writing to{" "}
          <StyledExternalLink href="mailto:accounts@karista.com.au">
            accounts@karista.com.au
          </StyledExternalLink>
        </p>
        <StyledGammaHeading><b>Important Contacts</b></StyledGammaHeading>
          <StyledContactText>Billing, subscription, direct debit enquiries:</StyledContactText>
          <StyledContactLink href="mailto:accounts@karista.com.au">accounts@karista.com.au</StyledContactLink>
          <StyledContactText>Client requests and referrals:</StyledContactText>
          <StyledContactLink href="mailto:clientservicesteam@karista.com.au">clientservicesteam@karista.com.au</StyledContactLink>
          <StyledContactText>Changing subscription, regions and services, capacity and wait time updates:</StyledContactText>
          <StyledContactLink href="mailto:provider-enquiriess@karista.com.au">provider-enquiries@karista.com.au</StyledContactLink>
          <StyledContactText>Support for increasing engagement:</StyledContactText>
          <StyledContactLink href="mailto:provider-enquiriess@karista.com.au">provider-enquiries@karista.com.au</StyledContactLink>
        <Columns>
          <WideCard>
            <CardHeading>Videos</CardHeading>
            <CardLead>
              Watch our helpful short videos which outline some important
              features about your admin portal and cover information on
              responding to requests and referrals.
            </CardLead>
            <VideoColumns>
              <MultiVideoPlayer
                videoLink="https://player.vimeo.com/video/932717543?h=5df29b5997&title=0&byline=0&portrait=0"
                caption="A step by step guide to setting up your profile with tips on how to stand out."
              />
              <MultiVideoPlayer
                videoLink="https://player.vimeo.com/video/932717570?h=5e6b030b7e&title=0&byline=0&portrait=0"
                caption="A guide to timely responses to requests and referrals, with steps for handling non-responses."
              />
              <MultiVideoPlayer
                videoLink="https://player.vimeo.com/video/932717594?h=1a25d021dc&title=0&byline=0&portrait=0"
                caption="A guide on what to say and email when contacting participants."
              />
              <MultiVideoPlayer
                videoLink="https://player.vimeo.com/video/932717617?h=d94463af0f&title=0&byline=0&portrait=0"
                caption="FAQs about requests and referrals, and tips to help you gain more!"
              />
              <MultiVideoPlayer
                videoLink="https://player.vimeo.com/video/986841854?h=d94463af0f&title=0&byline=0&portrait=0"
                caption="Information about reviews, tips on gaining more, and advice on the best ways to respond!"
              />
              <MultiVideoPlayer
                videoLink="https://player.vimeo.com/video/986841896?h=d94463af0f&title=0&byline=0&portrait=0"
                caption="A step by step guide on locating reviews in the Admin portal and how to respond."
              />
              <MultiVideoPlayer
                videoLink="https://player.vimeo.com/video/986841873?h=d94463af0f&title=0&byline=0&portrait=0"
                caption="We answer your billing and subscription questions in this quick video."
              />
            </VideoColumns>
          </WideCard>
          <WideCard>
            <CardHeading>Provider eToolkit</CardHeading>
            <CardLead>
              Download some useful items to help you make the most of your
              Karista subscription.
            </CardLead>
            <ToolkitColumns>{downloadItems}</ToolkitColumns>
          </WideCard>
        </Columns>
      </CenteredWithGutters>
    </div>
  );
};

export default WelcomeProvider;
