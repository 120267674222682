import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEmpty } from 'lodash';
import 'babel-polyfill';

import { MULTIPLE_PROVIDER_QUOTES } from '../constants';
import { ButtonLink } from '../../../components/Link';
import NoResults from '../components/NoResults';
import Pagination from '../../../components/Pagination';
import Provider from '../components/Provider';
import Section from '../../../components/Section';
import { DeltaHeading } from '../../../components/Typography';
import calculateRem from '../../../styles/calculateRem';
import mediaQueries from '../../../styles/mediaQueries';
import { colors } from '../../../styles/constants';
import { postcodeExactDetailText } from '../../../util/transforms';
import { createProviderListPaginationSubheading } from '../../../util/pagination';
import { AgeGroup, FundingType, Provider as ProviderT, Service } from '../../../state/types';
import { ProvidersActions } from '../actions';
import { clearProvidersToQuote, ProviderSearchOrder, setProviderSearchOrder, setProviderSearchPage, toggleProviderToQuote } from "../providersSlice";
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import karistaApi from "../../../services/karista";
import { useCreateNoProviderFoundContactRequestMutation } from '../../../services/karista';
import { reduceQueries } from '../../../util/rtk-query';

const StyledSection = styled(Section)`
  padding-top: ${calculateRem(48)};
`;

// TODO change to Single line paragraph
const ProviderCount = styled(DeltaHeading)`
  font-weight: 300;
`;

const CancelButton = styled.p`
  text-decoration: underline;
  cursor: pointer;
  margin-top: 12px;
`;

const QuoteDialog = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: ${colors.purple};
  color: ${colors.white};
  width: 100%;
`;

const QuoteDialogContainer = styled.section`
  margin: 0 auto;
  max-width: 720px;
  padding: 24px 24px 48px;
  display: flex;
  justify-content: space-between;
  ${mediaQueries.mobile`
    display: block;
    padding-bottom: 24px;
  `};
`;

const QuoteDialogActions = styled.aside`
  text-align: center;
  ${mediaQueries.mobile`
    margin-top: 24px;
  `};
`;

const ResultsSortSelectContainer = styled.div`
  text-align: end;
  margin-bottom: 1rem;
  ${mediaQueries.mobile`
    text-align: start;
  `};
`;

const ResultsSortSelectLabel = styled.label`
  font-size: 1rem;
`;

const ResultsSortSelect = styled.select`
  color: ${colors.darkPurple};
  padding: 2px;
  border: 1px solid ${colors.darkPurple};
  border-radius: 3px;
  font-size: 1rem;
  font-family: filson-soft, sans-serif;
  font-weight: 300;
`;

const useInitialize = () => reduceQueries({
  serviceAreas: karistaApi.useGetAllServiceAreasQuery(),
});

export interface ListProps extends RouteComponentProps {
  services?: Service[],
  ageGroups?: {[k: string]: AgeGroup},
  fundingTypes?: {[k: string]: FundingType},
  actions: ProvidersActions,
  loading: boolean,
  pageSize?: number
};

const List: React.FC<ListProps> = props => {
  const {
    actions,
    loading,
    pageSize=20,
    history,
    ageGroups,
    fundingTypes,
  } = props;

  const dispatch = useAppDispatch();
  const {
    query,
    page = 1,
    order = "by_score",
    filters,
    providersToQuote,
  } = useAppSelector(state => state.providers.providerSearchParams);

  const { data, isLoading: areProvidersLoading } = karistaApi.useGetProvidersQuery(
    { pageSize, query, page, ...filters, order },
  );

  const { serviceAreas, isLoading: servicesIsLoading } = useInitialize();

  const canSelectProviders = !!filters.service_areas_list && MULTIPLE_PROVIDER_QUOTES;
  const isQuoteDialogEnabled = (
    providersToQuote.length > 0
    && !!filters.service_areas_list
    && MULTIPLE_PROVIDER_QUOTES
  );

  const isLoading = (
    areProvidersLoading
    && !isEmpty(props.services)
    && !isEmpty(props.ageGroups)
    && !isEmpty(props.fundingTypes)
  );

  const [createNoProviderFoundContactRequest] = useCreateNoProviderFoundContactRequestMutation();

  if (isLoading) {
    // TODO: Make a common loading component. This could be done when suspense is added.
    return <div>Loading providers ...</div>;
  }

  if (!data || !data.count) {
    return <NoResults 
            filters={filters}
            ageGroups={props.ageGroups}
            fundingTypes={props.fundingTypes}
            serviceAreas={serviceAreas.data || null}
            createNoProviderFoundContactRequest={createNoProviderFoundContactRequest}
          />;
  }

  const modifiedData = (Object.keys(filters).length > 0) ? { 
      ...data, 
      results: data.results.reduce((acc: ProviderT[], elem: ProviderT) => {
        // Check if there's already a provider with the same account in acc
        const existingProviders = acc.filter(provider => provider.account === elem.account);

        if (existingProviders.length === 0) {
          acc.push(elem);
        } else {
          const allTelehealthFalse = existingProviders.every(provider => provider.is_telehealth_available === false);
          const allTelehealthTrue = existingProviders.every(provider => provider.is_telehealth_available === true);

          if (allTelehealthFalse && elem.is_telehealth_available === false) {
            acc.push(elem);
          } else if (allTelehealthTrue && elem.is_telehealth_available === true) {
            acc.push(elem);
          } else if (elem.is_telehealth_available === false) {
            // Remove other providers with the same account and add the new one
            acc = acc.filter(provider => provider.account !== elem.account);
            acc.push(elem);
          }
        }
        return acc;
      }, []),
      count: data.results.reduce((acc: ProviderT[], elem: ProviderT) => {
        const existingProviders = acc.filter(provider => provider.account === elem.account);

        if (existingProviders.length === 0) {
          acc.push(elem);
        } else {
          const allTelehealthFalse = existingProviders.every(provider => provider.is_telehealth_available === false);
          const allTelehealthTrue = existingProviders.every(provider => provider.is_telehealth_available === true);

          if (allTelehealthFalse && elem.is_telehealth_available === false) {
            acc.push(elem);
          } else if (allTelehealthTrue && elem.is_telehealth_available === true) {
            acc.push(elem);
          } else if (elem.is_telehealth_available === false) {
            acc = acc.filter(provider => provider.account !== elem.account);
            acc.push(elem);
          }
        }
        return acc;
      }, []).length
    }
  : data;

  return (
    <>
    <Helmet>
      <title>{`Connect with Disability and Home Care Service Providers`}</title>
      <meta name="description" content={`Looking for NDIS providers near you? Karista helps you find personalised disability and aged care services, therapies, and home care supports to meet your needs.`} />
    </Helmet>
    <StyledSection>
      <ProviderCount>
        <Subheading
          filters={filters}
          pagination={{current: page, ...modifiedData}}
          ageGroups={ageGroups}
          fundingTypes={fundingTypes}
        />
      </ProviderCount>
      <ResultsSortSelectContainer>
        <ResultsSortSelectLabel>Sort by: </ResultsSortSelectLabel>
        <ResultsSortSelect
          name="results_order"
          onChange={e => dispatch(setProviderSearchOrder(e.target.value as ProviderSearchOrder))}
        >
          <option value="highly_reviewed" selected={order !== "by_score"}>Highly reviewed</option>
          <option value="by_score" selected={order === "by_score"}>Top rated</option>
        </ResultsSortSelect>
      </ResultsSortSelectContainer>
      {loading || 
        modifiedData.results.map(elem => (
          <Provider
            key={elem.id}
            {...elem}
            url_slug={elem.url_slug}
            location={props.location}
            history={history}
            match={props.match}
            canSelect={canSelectProviders}
            providersToQuote={providersToQuote}
            onProviderSelect={providerId => dispatch(toggleProviderToQuote(providerId))}
          />
        ))
      }

      {modifiedData.totalPages > 1 && (
        <Pagination
          onClick={page => dispatch(setProviderSearchPage(page))}
          current={page}
          {...modifiedData}
        />
      )}
      {isQuoteDialogEnabled && (
        <QuoteDialog>
          <QuoteDialogContainer>
            <p>
              Please click <b>Next</b>  to securely provide a few details which helps us <br/>
              match you with the most suitable provider.
            </p>
            <QuoteDialogActions>
              <ButtonLink
                border
                to="/providers/request-quote"
              >
                Next
              </ButtonLink>
              <CancelButton onClick={() => dispatch(clearProvidersToQuote())}>
                cancel selections
              </CancelButton>
            </QuoteDialogActions>
          </QuoteDialogContainer>
        </QuoteDialog>
      )}
    </StyledSection>
    </>
  );
}

interface SubheadingProps {
  pagination: {
    current: number,
    pageSize: number,
    count: number,
  },
  filters: {[key: string]: string},
  ageGroups?: {[key: string]: AgeGroup},
  fundingTypes?: {[key: string]: FundingType},
};

function Subheading(props: SubheadingProps) {
  const { pagination, filters, ageGroups, fundingTypes } = props;
  const subheading = createProviderListPaginationSubheading(
    pagination.current,
    pagination.pageSize,
    pagination.count,
  );

  const { data: postcodesDetail } = karistaApi.useGetPostcodeQuery(
    !!filters.postcodes_list ? filters.postcodes_list : skipToken,
  );

  const serviceName = filters.service_areas_list;
  const ageDescription = (
    (ageGroups && filters.age_groups_list)
    ? ageGroups[filters.age_groups_list].description
    : null
  );
  const fundingName = (
    (fundingTypes && filters.funding_types_list)
    ? fundingTypes[filters.funding_types_list].name
    : null
  );
  const suburb = filters.suburb_list
  const postcode = filters.postcodes_list

  return (
    <>
      {subheading}
      {(serviceName || ageDescription || fundingName) && ' for'}
      {serviceName && ` ${serviceName}`}
      {ageDescription &&
        (serviceName ? `, ${ageDescription}` : ` ${ageDescription}`)}
      {fundingName &&
        (serviceName || ageDescription
          ? `, ${fundingName}`
          : ` ${fundingName}`)}
      {(suburb && postcode)
        ? ` in ${postcodeExactDetailText(suburb, postcode, postcodesDetail)}`
        : postcode
        ? ` in ${postcode}`
        : ''}.
  
      {serviceName && fundingName && ageDescription ? (
        <> 
          <b> Select one or more providers</b> and click <b>NEXT</b> to move forward.
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default List;
