import React, { useEffect } from 'react';
import { RouteComponentProps } from "react-router";
import { useGetQuoteDetailFormQuery } from "../../../services/karista";
import PageNotFound from "../../Static/PageNotFound";

export type QuoteDetailProps = RouteComponentProps<{ id?: string }>;

export const QuoteDetail: React.FC<QuoteDetailProps> = (props) => {
    const quoteId = props.match.params.id;

    if (!quoteId) {
        return <PageNotFound />;
    }

    const quoteDetailResult = useGetQuoteDetailFormQuery({ quoteId });

    useEffect(() => {
        if (quoteDetailResult.data) {
            const fundingType = quoteDetailResult.data.typeform_hidden_data.funding_type;
            if (fundingType && ["HCP", "HCP1", "HCP2", "HCP3", "HCP4"].includes(fundingType)) {
                props.history.push(`/providers/quote-detail/additional-questions-hcp/${quoteId}`);
            } else {
                props.history.push(`/providers/quote-detail/additional-questions-ndis/${quoteId}`);
            }
        }
    }, [quoteDetailResult.data, props.history, quoteId]);

    if (!quoteDetailResult.data) {
        return <div />;
    }

    return <div>Loading...</div>;
};
