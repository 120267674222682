import React from 'react';
import Theme from '../../../../../components/Theme';
import { themeLightGrey, themePrimary, themeSecondary , colors } from '../../../../../styles/constants';
import IconKeyWorker from "./ndis-introdcution-image.svg"
import { NumberList, ListItem, List } from '../../../style';
import NDISFAQs from '../NDISFAQs';
import IconClientServiceCircle from "../../HowCanWeHelp/icon-client-services-circle.svg";
import SupportCategories from '../SupportCategories';

import {
  NDISIntroductionIcon,
  NDISIntroductionIconContainer,
  NDISHeaderSection,
  HeaderText,
  ProviderIntroHeading,
  SupportCategorySection,
  NDISParagraph,
  NDISHelpSection,
  NDISHelpText,
  NDISIntroductionContentWrapper,
  HowWeCanHelpSection,
  FAQInfoText,
  NDISFAQParagraph,
  NDISClientServiceCircleIcon,
  NDISOtherText,
  HowWeCanHelpDiv,
  FirstListContainer,
  SecondListContainer,
  SupportCategoryList,
  HelpButtonSection,
  CategoryParagraph,
  CategoryHeadingParagraph,
  BackToTopCard,
  BackToTopCardInfoText
} from "../../../style";

import {
  Card,
  CardInfoText,
  CallLink,
} from '../../../../../apps/Providers/containers/styles'

const BackToTopButtons = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
      <BackToTopCard>
        <CallLink href="#" onClick={scrollToTop}>
          <BackToTopCardInfoText><b>Back to top</b></BackToTopCardInfoText>
        </CallLink>
      </BackToTopCard>
  );
}

const NDISIntroductionContent = () => (
  <>
    <ProviderIntroHeading><b>National Disability Insurance Scheme</b>
    </ProviderIntroHeading>
    <HeaderText>
        <NDISParagraph>
          <b>Understanding NDIS funding and its benefits</b>
        </NDISParagraph>
    </HeaderText>
  </>
);

const NDISIntroduction = () => {
  const openChat = e => {
    e.preventDefault();
    const chatButton = document.getElementById('esw-fab');
    if (chatButton) {
      chatButton.click();
    }
  }

  const SupportButtons = () => {
    return (
      <HelpButtonSection>
        <Card onClick={openChat} style={{marginBottom: '16px'}}>
          <CardInfoText><b>Chat with a team member</b></CardInfoText>
        </Card>
        <Card style={{marginLeft: '16px'}}>
          <CallLink href="/">
            <CardInfoText><b>Search for services now</b></CardInfoText>
          </CallLink>
        </Card>
      </HelpButtonSection>
    );
  }

  return (
    <>
    <Theme theme={themeSecondary}>
      <NDISHeaderSection>
        <NDISIntroductionContentWrapper>
            <NDISIntroductionContent />
        </NDISIntroductionContentWrapper>
        <NDISIntroductionIconContainer>
          <NDISIntroductionIcon src={IconKeyWorker} alt="Search NDIS services with Karista" />
        </NDISIntroductionIconContainer>
      </NDISHeaderSection>
      <Theme theme={themePrimary}>
        <NDISHelpSection>
          <div>
            <NDISHelpText>
              <b>How the NDIS helps people with disability</b>
            </NDISHelpText>
            <FirstListContainer>
              <List>
                <ListItem>
                  The National Disability Insurance Scheme (NDIS) is a transformative Australian Government initiative designed to empower individuals with disabilities.
                </ListItem><br />
                <ListItem>
                  The scheme aims to provide targeted support and better care to those in need, ensuring they can achieve their personal goals and live an enriched life.
                </ListItem><br />
                <ListItem>
                  Every NDIS participant has an individual plan that lists their goals and the funding they have received.
                </ListItem><br />
              </List>
            </FirstListContainer>
          </div>
            <SecondListContainer>
              <List>
                <ListItem>
                  The funding can be used for NDIS services and supports that help participants pursue their goals which could include things like living independently, getting and keeping a job, making friends or participating in a local community activity.
                </ListItem><br />
                <ListItem>
                  Participants (or their care giving relatives) control the NDIS support they receive, when they receive it, and who provides it.
                </ListItem><br />
              </List>
            </SecondListContainer>
        </NDISHelpSection>
      </Theme>
      <SupportCategorySection>
        <CategoryParagraph>
          <b>NDIS funding is made up of support categories.</b>
        </CategoryParagraph>
        <CategoryHeadingParagraph><b>Each support category has information about the types of supports you can use your funding for and is grouped into:</b></CategoryHeadingParagraph>
        <SupportCategoryList>
          <NumberList>
            <ListItem>Core supports</ListItem>
            <ListItem>Capacity building supports</ListItem>
            <ListItem>Capital supports</ListItem><br></br>
          </NumberList>
        </SupportCategoryList>
      </SupportCategorySection>
      <SupportCategories></SupportCategories>
      <BackToTopButtons></BackToTopButtons>
      <NDISFAQs></NDISFAQs><br></br>
      <BackToTopButtons></BackToTopButtons>
      <Theme theme={themeLightGrey}>
        <NDISHeaderSection>
          <NDISClientServiceCircleIcon>
              <img src={IconClientServiceCircle} alt='Karista is a platform that connects people with disability to NDIS registered providers' />
          </NDISClientServiceCircleIcon>
          <HowWeCanHelpDiv>
            <SupportCategorySection style={{ paddingRight: '10px', paddingLeft: '10px'}}>
                <NDISOtherText>
                    <b>How Karista can help</b>
                </NDISOtherText>
            </SupportCategorySection>
            <HowWeCanHelpSection>
                <FAQInfoText style={{color: colors.black}}>Karista is a platform that connects people with disability to NDIS registered providers. 
                  We simplify the process of finding NDIS services and supports that cater to specific needs. </FAQInfoText>
                <NDISFAQParagraph style={{ paddingTop: '10px' }}>Our goal is to make the journey towards a better quality of life as seamless as possible and to provide better care at total lower cost. 
                  You can read more about us  <a href="/about">here</a>.
                </NDISFAQParagraph>
            </HowWeCanHelpSection>
            <SupportButtons />
          </HowWeCanHelpDiv>
        </NDISHeaderSection>
      </Theme>
    </Theme>
    </>
  );
}

export default NDISIntroduction;