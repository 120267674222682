import React from 'react';
import {ButtonTransparentWhite} from "../Button";
import Autosuggest from 'react-autosuggest';
import karistaApi from "../../services/karista";
import {debounce} from "lodash";
import styled from 'styled-components';
import "./style.css"

const HighlightedText = styled.em`
  font-weight: ${({ queryMatch }) => (queryMatch ? 'bold' : 'normal')};
  color: ${({ queryMatch }) => (queryMatch ? 'black' : 'inherit')};
`;

const PostcodeText = styled.span`
  color: grey;
`

const HighlightedSuggestion = ({ query, children }) => {
  const parts = children.split(new RegExp(`(${query})`, 'gi'));

  return (
    <span>
      {parts.map((part, index) => (
        <HighlightedText key={index} queryMatch={part.toLowerCase() === query.toLowerCase()}>
          {part}
        </HighlightedText>
      ))}
    </span>
  );
};

export const defaultSearchResultsRender = suggestion => (
  <div className="autocomplete-item">{suggestion}</div>
);

export const postcodeSearchResultsRender = (suggestion, searchQuery) => {
  return (
    <div className="autocomplete-item autocomplete-item--truncated"
      // Showing tooltip for lengthy text
      title={`${suggestion.label} ${suggestion.code}`}>
      <PostcodeText>
        <HighlightedSuggestion query={searchQuery}>
          {`${suggestion.label} ${suggestion.code}`}
        </HighlightedSuggestion>
      </PostcodeText>
    </div>
  );
};

interface IndexSearchProps {
  placeholder?: string,
  handleNoMatches: Function,
  inputComponent: React.ReactNode,
  onSuggestionSelected?: Function,
  handleClick?: Function,
  spinning?: boolean,
  hasRightMargin?: boolean,
  maxAutoSuggestionResults?: number,
  inputProps?: any
};

type PostcodeSuggestion = {code: string, label: string};

export const PostcodeSearchBox: React.FC<Omit<IndexSearchProps, 'handleNoMatches'>> = props => {
  const [query, setQuery] = React.useState<string>("");
  const [suggestions, setSuggestions] = React.useState<PostcodeSuggestion[]>([]);
  const [triggerQuery, result] = karistaApi.useLazyGetPostcodesQuery();


  const reducePostcodes = (postcodes) => {
    let accum = [];
    for (const postcode of postcodes) {
      const labels = postcode.labels || "";

      //Filtering the labels based upon the search query in case of suburbs
      if (labels.toLowerCase().includes(query.toLowerCase())) {
        accum = accum.concat(labels.split(", ").flatMap(label => {
          const labelParts = label.split("/");
          return labelParts
            .map(part => part.trim())
            .filter(part => part.toLowerCase().includes(query.toLowerCase()))
            .map(part => ({code: postcode.code, label: part.trim()}));
        }));
      }

      //No filtering required in case search query is postcodes
      if (postcode.code.toLowerCase().includes(query.toLowerCase())) {
        accum = accum.concat(labels.split(", ").flatMap(label => {
          const labelParts = label.split("/");
          return labelParts.map(part => ({code: postcode.code, label: part.trim()}));
        }));
      }
    }
    return accum;
  };
  
  

  React.useEffect(
      () => {
          setSuggestions(result.isSuccess ? reducePostcodes(result.data) : [])
      }, [result.data]);

  React.useEffect(
      () => {
        if (query && query.length >= 3) {
          triggerQuery(query);
        }
      }, [query]
  )

  const handleUpdateSuggestions = debounce((update) => {
      if (update.value !== query) {
          setQuery(update.value);
      }
  }, 300);

  const handleClearSuggestion = () => {
    setSuggestions([]);
  }
  const inputProps = props.inputProps || {
    placeholder: props.placeholder,
    value: query,
    onChange: () => {},
    autoFocus: false
  };

  const searchButton = props.handleClick ? (
    <div className="search-button">
      <ButtonTransparentWhite
        fullWidth
        strong
        spinning={props.spinning}
        type="submit"
      >
        Search
      </ButtonTransparentWhite>
    </div>
  ) : null;
  return (
    <div className="search-container">
      <div
        className={
          props.hasRightMargin ? 'searchbox' : 'searchbox-plain'
        }
      >
        <div className="autocomplete">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsClearRequested={handleClearSuggestion}
          onSuggestionsFetchRequested={handleUpdateSuggestions}
          getSuggestionValue={(suggestion) => suggestion.code}
          renderSuggestion={(suggestion) =>
            postcodeSearchResultsRender(suggestion, query)
          }
          inputProps={inputProps}
          renderInputComponent={props.inputComponent}
          focusInputOnSuggestionClick={false}
          onSuggestionSelected={(e, suggestion) =>
            props.onSuggestionSelected && props.onSuggestionSelected(e, suggestion)
          }
        />
        </div>
      </div>
      {searchButton}
    </div>
  );
}

export const ProviderSearchBox: React.FC<Omit<IndexSearchProps, 'handleNoMatches'>> = props => {
  const [query, setQuery] = React.useState<string>("");
  const [suggestions, setSuggestions] = React.useState<string[]>([]);
  const [triggerQuery, result] = karistaApi.useLazyGetProvidersQuery();

  React.useEffect(
      () => {
          setSuggestions(result.isSuccess ? result.data.results.map(p => p.name) : [])
      }, [result.data]);

  React.useEffect(
      () => {
        if (query && query.length >= 3) {
          triggerQuery({query, pageSize: 5});
        }
      }, [query]
  )

  const handleUpdateQuery = debounce((event, params) => {
    setQuery(params.newValue);
  }, 300);

  const handleUpdateSuggestions = (suggestions) => {
      return;
  }

  const handleClearSuggestion = () => {
    setSuggestions([]);
  }
  const inputProps = {
    placeholder: props.placeholder,
    value: query,
    onChange: handleUpdateQuery,
    autoFocus: false
  };

  const searchButton = props.handleClick ? (
    <div className="search-button">
      <ButtonTransparentWhite
        fullWidth
        strong
        spinning={props.spinning}
        type="submit"
      >
        Search
      </ButtonTransparentWhite>
    </div>
  ) : null;
  return (
    <div className="search-container">
      <div
        className={
          props.hasRightMargin ? 'searchbox' : 'searchbox-plain'
        }
      >
        <div className="autocomplete">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsClearRequested={handleClearSuggestion}
            onSuggestionsFetchRequested={handleUpdateSuggestions}
            getSuggestionValue={suggestion => suggestion}
            renderSuggestion={defaultSearchResultsRender}
            inputProps={inputProps}
            renderInputComponent={props.inputComponent}
            focusInputOnSuggestionClick={false}
            onSuggestionSelected={props.onSuggestionSelected}
          />
        </div>
      </div>
      {searchButton}
    </div>
  );
}
