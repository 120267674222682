import styled from "styled-components";
import Section from "../../../../components/Section";
import { BetaHeading } from "../../../../components/Typography";
import mediaQueries from "../../../../styles/mediaQueries";
import {colors} from "../../../../styles/constants";

const cardStackBreakpoint = '720px';

export const FormSection = styled(Section)`
  background: ${colors.white};
  height: fit-content;
  text-align: left;
  padding-top: 4%;
  margin-bottom: 24px;
  border-radius: 14px;
  border: 2px solid ${colors.orange};
  
  @media (max-width: ${cardStackBreakpoint}) {
    background: ${colors.lightGrey};
    padding: 20px 40px;
    margin-bottom: 4px;
  }
`;

export const BetaHeaderHeading = styled(BetaHeading)`
  margin-bottom: 8px;

  ${mediaQueries.mobile`
    display: none;
  `};
`;

export const HeaderText = styled.p`
  font-size: 17px;
  margin-bottom: 8px;
  color: ${colors.black};
`;

export const HeaderTextPurple = styled.p`
  font-size: 17px;
  margin-bottom: 8px;
  color: ${colors.purple};
`;

export const HeaderSection = styled.div`
  text-align: left;
 
  ${mediaQueries.mobile`
    width: auto;
  `};
`;