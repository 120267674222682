/* eslint-disable react/no-danger */
import React from 'react';
import { GammaHeading } from '../Typography';
import StyledLink from '../Link';
import Tag, { FundingTypeTag } from '../Tag';
import { WideLink } from '../ProfileHeader';

import {
  StyledList,
  ButtonsContainer,
  DetailsSection,
  TransparentButton
} from './styles';
import { FundingType, Service, InternalLinks } from '../../state/types';

const ProviderContact = ({ phoneNumber = '' }) => (
  <StyledList>
    <li>{phoneNumber || null}</li>
  </StyledList>
);

ProviderContact.defaultProps = {
  phoneNumber: null
};

const ActionButtons = ({ providerId, providerSlug }) => (
  <ButtonsContainer>
    <StyledLink to={`/providers/${providerSlug}/${providerId}/review/`}>
      <TransparentButton>Review this provider</TransparentButton>
    </StyledLink>
    <StyledLink to={`/providers/${providerSlug}/${providerId}/request-quote/`}>
      <WideLink>Connect with the Provider</WideLink>
    </StyledLink>
  </ButtonsContainer>
);

interface ProviderContentProps {
  fundingTypes?: FundingType[],
  phoneNumber?: string | null,
  services: Service[],
  providerId: string | number,
  description: string | null,
  isActive?: boolean,
  providerSlug: string | null,
  serviceAreaMap?: Record<number, string>,
  internalLinksData?: InternalLinks[]
};

export const ProviderContent: React.FC<ProviderContentProps> = ({
  fundingTypes,
  phoneNumber,
  services,
  providerId,
  description,
  isActive,
  providerSlug,
  serviceAreaMap,
  internalLinksData
}) => {
  const getFormattedDescription = (
    desc: string,
    internalLinksData: { keyword: string; display_words: string; link: string }[] | undefined
  ) => {
    if (!internalLinksData || internalLinksData.length === 0) return desc;
  
    // Construct the replacements map dynamically
    const replacements: Record<string, string> = internalLinksData.reduce((acc, { keyword, display_words, link }) => {
      acc[keyword.toLowerCase()] = `<a href="${link}" rel="noopener noreferrer">${display_words}</a>`;
      return acc;
    }, {} as Record<string, string>);
  
    // Create a regex pattern from the keywords
    const pattern = new RegExp(`\\b(${Object.keys(replacements).join("|")})\\b`, "gi");
  
    return desc.replace(pattern, (match) => replacements[match.toLowerCase()]);
  };
  
  return (
  <div>
    {phoneNumber && <ProviderContact phoneNumber={phoneNumber} />}
    {isActive && <ActionButtons providerId={providerId as string} providerSlug={providerSlug} />}
    {description && (
      <DetailsSection>
        <GammaHeading>About </GammaHeading>
        <div dangerouslySetInnerHTML={{ __html: getFormattedDescription(description, internalLinksData) }} />
      </DetailsSection>
    )}
    {fundingTypes && fundingTypes.length ? (
      <DetailsSection>
        <GammaHeading>Funding types</GammaHeading>
        {fundingTypes
          .filter(fundingType => fundingType.hcp_allowed === false)
          .map(filteredFundingType => {
            const fundingLinks = {
              "NDIS": "/funding-information/national-disability-insurance-scheme",
              "HCP": "/funding-information/home-care-package-funding"
            };
            return fundingLinks[filteredFundingType.code] ? (
              <StyledLink to={fundingLinks[filteredFundingType.code]} key={filteredFundingType.code}>
                <FundingTypeTag label={filteredFundingType.name} />
              </StyledLink>
            ) : (
              <FundingTypeTag key={filteredFundingType.code} label={filteredFundingType.name} />
            );
          })}
      </DetailsSection>
    ) : (
      ''
    )}
    {services && services.length ? (
      <DetailsSection>
        <GammaHeading>Services</GammaHeading>
        {services.map(s => {
          const serviceSlug = serviceAreaMap && serviceAreaMap[s.service_area]; // Get slug from map

          return serviceSlug ? (
            <StyledLink key={`service-${s.id}`} to={`/services/${serviceSlug}`}>
              <Tag label={s.name} />
            </StyledLink>
          ) : (
            <Tag key={`service-${s.id}`} label={s.name} />
          );
        })}
      </DetailsSection>
    ) : (
      ''
    )}
  </div>
)};

ProviderContent.defaultProps = {
  services: [],
  fundingTypes: [],
  phoneNumber: null,
  description: null,
  isActive: true
};

export default ProviderContent;
